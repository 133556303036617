import { memo, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { Step, Stepper } from 'components/Stepper/Stepper';
import { convertSecondsToMinutes, formatTimeWithUnits } from 'lib/convertSecondsToTime';
import { useGetKpiStatisticsQuery } from 'api/generated';
import userContext from 'context/userContext';
import { StepperWarningBar } from 'components/StepperWarningBar/StepperWarningBar';
import { OutlinedBadge } from 'components/OutlinedBadge/OutlinedBadge';
import { Tooltip, TooltipVariant } from 'components/Tooltip';

type Props = {
  active: boolean;
  queuedOrderCount: number;
  readyToPickCount: number;
  pickingCount: number;
  readyToDeliverCount: number;
  externalReadyToDeliverCount: number;
  pickersNeededCount: number;
  bottleneck: string;
  isHubLevelViewActive?: boolean;
};

export enum StatTypeEnum {
  HUGE = 'HUGE',
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
}

type GetHumanizeNumberType = (number: number) => number;

export const humanizeNumber: GetHumanizeNumberType = (number) =>
  !Number.isNaN(number) ? Math.round(number) : 0;

const Stat = ({
  number,
  text,
  isHubLevelViewActive,
  tooltipText,
  className = '',
}: {
  number?: string | number;
  text: string;
  isHubLevelViewActive?: boolean;
  tooltipText?: string;
  className?: string;
}) => {
  const ref = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  return (
    <>
      <div
        ref={ref}
        onMouseOver={() => isHubLevelViewActive && setTooltipVisible(true)}
        onMouseLeave={() => isHubLevelViewActive && setTooltipVisible(false)}
        className={`title-m text-flinkGray-light ${
          isHubLevelViewActive
            ? 'flex flex-col justify-center text-center normal-case'
            : 'lowercase'
        } ${className}`}
      >
        <span className={`text-white ${isHubLevelViewActive ? 'font-medium' : 'font-bold'}`}>
          {number}
        </span>
        <span className={`${isHubLevelViewActive ? 'title-s font-normal' : ''}`}>{` ${text}`}</span>
      </div>

      {isHubLevelViewActive && (
        <Tooltip
          refElement={ref?.current || null}
          visible={tooltipVisible}
          variant={TooltipVariant.pink}
          placement="bottom"
        >
          <div className="max-w-[300px]">{tooltipText}</div>
        </Tooltip>
      )}
    </>
  );
};

const Stats = ({
  isHubLevelViewActive = false,
  active,
  queuedOrderCount,
  readyToPickCount,
  pickingCount,
  readyToDeliverCount,
  externalReadyToDeliverCount,
  pickersNeededCount,
  bottleneck,
}: Props) => {
  const { t } = useTranslation();
  const { selectedHub } = useContext(userContext);
  const slug = selectedHub?.slug || '';
  const { data } = useGetKpiStatisticsQuery({
    variables: { slug },
    pollInterval: 120000,
  });
  const isPickersNeeded = pickersNeededCount > 0;
  const isPickingBottleneckActive =
    bottleneck === 'picking_bottleneck' && pickingCount < pickersNeededCount;

  const isOutlinedBadgeExist = isPickersNeeded || externalReadyToDeliverCount > 0;
  return (
    <>
      {isHubLevelViewActive && (
        <div className="w-full rounded bg-flinkGray">
          <StepperWarningBar
            show={isPickingBottleneckActive}
            label={t('stats_warning_bar_label')}
            tooltip={t('stats_warning_bar_tooltip')}
            isHubLevelViewActive={isHubLevelViewActive}
          />
        </div>
      )}
      <div
        className={`cursor-default ${isHubLevelViewActive ? 'flex flex-row bg-flinkGray' : ''} ${
          isPickingBottleneckActive ? 'rounded-b-lg' : 'rounded-lg'
        } `}
      >
        <div
          className={`flex ${
            isHubLevelViewActive
              ? 'mx-auto gap-6 border-r border-flinkGray-medium px-6'
              : 'my-4 flex items-end justify-between'
          }`}
        >
          <div
            className={`${
              isHubLevelViewActive ? 'my-2 grid grid-flow-col grid-rows-1 gap-6' : 'flex gap-8'
            } `}
          >
            <Stat
              number={data?.kpiStatistics?.totalOrders || 0}
              text={t('stats_total_orders_caption_hub_level')}
              isHubLevelViewActive={isHubLevelViewActive}
              tooltipText={t('stats_total_orders_tooltip')}
            />
            {isHubLevelViewActive && (
              <Stat
                number={formatTimeWithUnits(data?.kpiStatistics?.averageFulfillmentTime || 0)}
                text={t('stats_avg_fulfillment_time_hub_level')}
                isHubLevelViewActive={isHubLevelViewActive}
                tooltipText={t('stats_avg_fulfillment_time_tooltip')}
                className="w-24 break-words"
              />
            )}

            {!isHubLevelViewActive && (
              <>
                <Stat
                  number={`${data?.kpiStatistics?.percentageOfDeliveriesOnTime || 0}%`}
                  text={t('stats_ontime_delivery_caption_hub_level')}
                  isHubLevelViewActive={isHubLevelViewActive}
                  tooltipText={t('stats_ontime_delivery_tooltip')}
                />
                <Stat
                  number={`${convertSecondsToMinutes(
                    data?.kpiStatistics?.averageRiderTime || 0,
                    false
                  )} ${t('stats_rider_unit')}`}
                  text={t('stats_rider_caption')}
                />
                <Stat
                  number={`${data?.kpiStatistics?.averageProductPickingTime || 0} ${t(
                    'stats_picker_unit'
                  )}`}
                  text={t('stats_picker_caption')}
                />
              </>
            )}
          </div>
        </div>
        <div className={`w-full rounded bg-flinkGray ${isOutlinedBadgeExist ? '' : 'mt-4'}`}>
          {!isHubLevelViewActive && (
            <StepperWarningBar
              show={isPickingBottleneckActive}
              label={t('stats_warning_bar_label')}
              tooltip={t('stats_warning_bar_tooltip')}
            />
          )}
          <Stepper isHubLevelViewActive={isHubLevelViewActive}>
            <Step
              minimized={active}
              count={queuedOrderCount}
              label={t('stats_in_queue')}
              isHubLevelViewActive={isHubLevelViewActive}
              isOutlinedBadgeExist={isOutlinedBadgeExist}
            />
            <Step
              minimized={active}
              count={readyToPickCount}
              label={t('stats_ready_to_pick')}
              isHubLevelViewActive={isHubLevelViewActive}
              isOutlinedBadgeExist={isOutlinedBadgeExist}
            />
            <Step
              variant={isPickingBottleneckActive ? 'orange' : isPickersNeeded ? 'pink' : 'default'}
              minimized={active}
              count={pickingCount}
              label={t('stats_picking')}
              isHubLevelViewActive={isHubLevelViewActive}
              isOutlinedBadgeExist={isOutlinedBadgeExist}
            >
              {isPickersNeeded && (
                <OutlinedBadge
                  isHubLevelViewActive={isHubLevelViewActive}
                  label={t(
                    `${
                      isHubLevelViewActive
                        ? 'stats_pickers_needed_hub_level'
                        : 'stats_pickers_needed'
                    }`,
                    { count: pickersNeededCount }
                  )}
                  borderColor={
                    isPickingBottleneckActive ? 'border-orange-400' : 'border-flinkPink-medium'
                  }
                  textColor={
                    isPickingBottleneckActive ? 'text-orange-400' : 'text-flinkPink-medium'
                  }
                />
              )}
            </Step>
            <Step
              variant="blue"
              minimized={active}
              count={readyToDeliverCount}
              label={t('stats_ready_to_deliver')}
              isHubLevelViewActive={isHubLevelViewActive}
            >
              {externalReadyToDeliverCount > 0 && (
                <OutlinedBadge
                  isHubLevelViewActive={isHubLevelViewActive}
                  label={t('stats_external_orders', { count: externalReadyToDeliverCount })}
                  borderColor="border-blue-400"
                  textColor="text-blue-400"
                />
              )}
            </Step>
          </Stepper>
        </div>
      </div>
    </>
  );
};

export default memo(Stats, isEqual);
