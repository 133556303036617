type EventListener = (data: any) => void;

export enum Events {
  VIEW_RIDER_LOCATIONS = 'view_riders_locations',
  RIDER_STATES = 'rider_states',
  UNDERAGE_RIDERS = 'underage_riders',
  TEMP_OFFLINE_RIDERS = 'temp_offline_riders',
}

const on = (eventType: Events, listener: EventListener) => {
  document.addEventListener(eventType, listener);
};

const off = (eventType: Events, listener: EventListener) => {
  document.removeEventListener(eventType, listener);
};

const once = (eventType: Events, listener: EventListener) => {
  const handleEventOnce = (data: any) => {
    listener(data);
    off(eventType, handleEventOnce);
  };

  on(eventType, handleEventOnce);
};

const trigger = (eventType: Events, data = {}) => {
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
};

export { on, once, off, trigger };
