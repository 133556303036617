import useOnClickOutside from 'hooks/useOnClickOutside';
import { useRef } from 'react';
import { PopoverWrapper, PopoverContent } from './style';
import { PopoverProps } from './types';

const Popover = ({
  refElement,
  open = false,
  onClose,
  children,
}: React.PropsWithChildren<PopoverProps>): JSX.Element => {
  const popoverRef = useRef(null);

  useOnClickOutside(popoverRef, refElement, () => {
    onClose && onClose();
  });

  return (
    <PopoverWrapper
      ref={popoverRef}
      open={open}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {open && <PopoverContent>{children}</PopoverContent>}
    </PopoverWrapper>
  );
};

export { Popover };
