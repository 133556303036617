import { useEffect, useRef } from 'react';

import useKeyPress from 'hooks/useKeyPress';
import useOutsideClick from 'hooks/useOutsideClick';

type Props = {
  isOpen?: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  children: React.ReactNode;
  submitText?: string;
  cancelText?: string;
  isDisabled?: boolean;
};

const Modal = ({
  isOpen,
  onConfirm,
  onCancel,
  children,
  submitText,
  cancelText,
  isDisabled,
}: Props) => {
  const ref = useRef(null);
  const escape = useKeyPress('Escape');

  useEffect(() => {
    // disable background scrolling when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = '';
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if (escape) {
      onCancel();
    }
  }, [escape, onCancel]);

  useOutsideClick(ref, () => {
    if (isOpen) {
      onCancel();
    }
  });

  return isOpen ? (
    <div className="fixed inset-0 z-9999 overflow-y-auto" role="dialog" aria-modal="true">
      <div className="flex min-h-screen items-start justify-center pt-4 pb-20">
        <div
          className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"
          aria-hidden="true"
        ></div>

        <div
          ref={ref}
          className="mx-auto mt-40 flex w-full max-w-2xl transform flex-col overflow-hidden rounded-lg bg-flinkGray-medium py-12 text-left align-bottom text-white shadow-xl transition-all"
        >
          {children}
          <div className="flex w-full flex-row justify-center px-6">
            {cancelText && (
              <button
                type="button"
                className="min-w-40 mr-4 w-auto rounded bg-gray-100 py-2 px-4 text-center text-sm font-semibold text-gray-800"
                onClick={onCancel}
              >
                {cancelText}
              </button>
            )}
            {submitText && (
              <button
                type="submit"
                className="min-w-40 w-auto rounded bg-flinkPink py-2 px-4 text-center text-sm font-semibold disabled:opacity-70"
                disabled={isDisabled}
                onClick={onConfirm}
              >
                {submitText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
