import styled from 'styled-components';

export const CodeWrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

export const Content = styled.div`
  display: flex;
  margin-bottom: 1rem;

  svg {
    width: 4rem;
  }

  p {
    margin-left: 1rem;
    ${(props) => props.theme.mixins.textSmall()}
  }
`;
