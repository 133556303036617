//
// The page should be refreshed
// only when mounted and polled dates are not equal
// and only after 4am
//
const compareDatesToRefresh = (pageMountedDate: Date, polledDate: Date) => {
  const mountedDay = pageMountedDate.getUTCDate();
  const mountedMonth = pageMountedDate.getUTCMonth();

  const polledDay = polledDate.getUTCDate();
  const polledMonth = polledDate.getUTCMonth();
  const polledHours = polledDate.getUTCHours();

  return !(mountedDay === polledDay && mountedMonth === polledMonth) && polledHours >= 4;
};

export default compareDatesToRefresh;
