import styled from 'styled-components';

export const Block = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem;
`;

export const PopoverWidth = 280;

export const Popover = styled.div`
  padding: ${({ theme }) => theme.sizes['5']};
  width: ${PopoverWidth}px;
  position: absolute;
  bottom: 0;
  transform: translate(${PopoverWidth / 3.5}px, -4rem);

  border-radius: ${({ theme }) => theme.sizes['1.5']};
  background-color: ${({ theme }) => theme.colors.GRAY_MEDIUM};
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));

  &:after {
    position: absolute;
    bottom: -1.25rem;
    left: 50%;
    transform-origin: center;
    transform: rotate(45deg) translate(-1rem, 0);
    display: block;
    content: '';
    width: 1rem;
    height: 1rem;
    background-color: ${({ theme }) => theme.colors.GRAY_MEDIUM};
  }
`;

export const Button = styled.button`
  all: unset;
  ${({ theme }) => theme.mixins.textBold()}
  cursor: pointer;
`;

export const CloseButton = styled.button`
  all: unset;
  position: absolute;
  top: -14px;
  right: -14px;
  cursor: pointer;

  .icon:before {
    background-color: ${({ theme }) => theme.colors.GRAY_DARK};
  }
`;
