import { useState } from 'react';
import { useGetPickersNeededQuery } from 'api/generated';

type UsePickersNeededVariables = {
  slug: string;
};

const ERROR_ALLOWANCE_LIMIT = 4;
const FETCH_INTERVAL = 10000;

const usePickersNeeded = ({ slug }: UsePickersNeededVariables) => {
  const [isPollingStopped, setIsPollingStopped] = useState<boolean>(false);
  const [errorCount, setErrorCount] = useState(0);

  const { data, stopPolling, error } = useGetPickersNeededQuery({
    variables: { slug },
    defaultOptions: {
      fetchPolicy: 'network-only',
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: FETCH_INTERVAL,
    onCompleted: () => {
      if (errorCount) {
        setErrorCount(0);
      }
    },
    onError: ({ networkError, graphQLErrors }) => {
      if (errorCount >= ERROR_ALLOWANCE_LIMIT && (networkError || graphQLErrors)) {
        stopPolling();
        setIsPollingStopped(true);
      }
      setErrorCount((prevCount) => prevCount + 1);
    },
  });

  return {
    pickersNeeded: data?.pickersNeeded || 0,
    isPollingStopped,
    error,
  };
};

export default usePickersNeeded;
