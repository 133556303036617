import React, { memo, useLayoutEffect, useRef } from 'react';
import { FloatingPortal } from '@floating-ui/react';
import {
  autoUpdate,
  MiddlewareArguments,
  offset,
  size,
  arrow,
  useFloating,
  Placement,
} from '@floating-ui/react-dom';

enum TooltipVariant {
  default = 'default',
  pink = 'pink',
}

const getVariantClass = (variant: TooltipVariant) => {
  switch (variant) {
    case TooltipVariant.default:
      return 'bg-primary text-gray-300';
    case TooltipVariant.pink:
      return 'bg-pink-200 text-black';
    default:
      return '';
  }
};

const PlacementOpposite: Record<string, string> = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};

interface TooltipProps extends React.HTMLAttributes<HTMLElement> {
  refElement: Element | null;
  visible?: boolean;
  variant?: TooltipVariant;
  placement?: Placement;
}

const TooltipFC = ({
  refElement,
  visible,
  variant = TooltipVariant.default,
  placement = 'bottom-start',
  children,
}: TooltipProps): JSX.Element => {
  const arrowRef = useRef(null);
  const {
    x,
    y,
    reference,
    floating,
    strategy,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    placement,
    strategy: 'fixed',
    whileElementsMounted: (ref, flt, update) =>
      autoUpdate(ref, flt, update, {
        animationFrame: true,
      }),
    middleware: [
      offset(8),
      arrow({ element: arrowRef }),
      size({
        apply({ availableWidth, elements }: MiddlewareArguments & { availableWidth: number }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
          });
        },
      }),
    ],
  });

  useLayoutEffect(() => {
    reference(refElement);
  }, [visible, refElement]);

  return (
    <FloatingPortal>
      <div ref={floating} style={{ zIndex: 999999, position: strategy, top: y ?? 0, left: x ?? 0 }}>
        <div
          className={`detail-m min-w-fit flex-col rounded px-4 py-2 shadow-sm ${
            visible ? 'flex' : 'hidden'
          } ${getVariantClass(variant)}`}
        >
          <div className="max-w-[310px]">{children}</div>
          <div
            ref={arrowRef}
            className="absolute h-2 w-2 rotate-45 bg-inherit"
            style={{
              left: arrowX != null ? `${arrowX}px` : '',
              top: arrowY != null ? `${arrowY}px` : '',
              right: '',
              bottom: '',
              [PlacementOpposite[placement.split('-')[0] as keyof typeof PlacementOpposite]]:
                '-4px',
            }}
          />
        </div>
      </div>
    </FloatingPortal>
  );
};

const Tooltip = memo(TooltipFC);
export { Tooltip, TooltipVariant };
export type { TooltipProps };
