import { lazy, memo, Suspense, useMemo, useState } from 'react';
import { type ListTrip } from 'utils/trips';

import { RiderStates, StateCodesEnum, TripActionModalType } from 'lib/constants';
import { useTranslation } from 'react-i18next';
import { ModalWrapper } from 'components/Map/ModalWrapper';
import OrderComponent from 'components/Order/Order';
import Icon from 'components/Icon/Icon';
import { Order, Rider, ShippingMethod } from 'api/generated';
import { isInternalTrip, isJetTrip } from 'pages/Dashboard/helpers';
import { randomIntFromInterval } from 'lib/orderHelpers';

const TripMap = lazy(() => import('components/TripMap/TripMap'));

type Props = {
  trip: ListTrip;
  onAction: (actionType: TripActionModalType, data: Order[]) => void;
  isAllowedToUpdate: boolean;
};

const TripList = ({ trip, onAction, isAllowedToUpdate }: Props) => {
  const { t } = useTranslation();
  const [mapVisible, setMapVisible] = useState(false);

  const isUnstackable =
    isAllowedToUpdate &&
    trip?.orders?.length > 1 &&
    trip?.orders?.every((order) =>
      [StateCodesEnum.CREATED, StateCodesEnum.PICKERACCEPTED, StateCodesEnum.PACKED].includes(
        order.state as number
      )
    );

  //TODO: Remove unnecessary "order.trackingId" condition when the trip starts problem is resolved.
  const isTripCompletable =
    isAllowedToUpdate &&
    trip.orders.some(
      (order) =>
        [StateCodesEnum.RIDERCLAIMED, StateCodesEnum.ONROUTE, StateCodesEnum.ARRIVED].includes(
          order.state as number
        ) &&
        order.riderId && // if there is no rider id - it means outsourced
        order.trackingId
    );

  const isTripUnassignable =
    isAllowedToUpdate &&
    trip.orders.every(
      (order) =>
        [StateCodesEnum.RIDERCLAIMED, StateCodesEnum.ONROUTE].includes(order.state as number) &&
        order.riderId &&
        order.trackingId
    );

  const isTripAssignable =
    isAllowedToUpdate &&
    (isInternalTrip(trip) || isJetTrip(trip)) &&
    trip.orders.every((order) => order.state === StateCodesEnum.PACKED) &&
    !trip.orders.every((order) => order.shippingMethod === ShippingMethod.InStorePayment) &&
    !trip.orders.every((order) => order.isOutsourced);

  // a rider may have multiple orders, pick the order with state en-route
  const activeOrderNumber = useMemo(() => {
    if (trip.orders[0]?.rider?.status === RiderStates.BUSY) {
      return trip.orders.find((order) => order.state === StateCodesEnum.ONROUTE)
        ?.orderNumber as string;
    }
  }, [trip.orders]);

  return (
    <div
      key={
        trip?.orders?.length > 0 ? trip?.orders[0]?.id : `trip-${randomIntFromInterval(1, 1000)}`
      }
      className={
        trip?.orders?.length > 1
          ? 'mb-3 rounded-lg border-4 border-flinkGray-light bg-flinkGray-dark shadow-2m'
          : 'border-b-2 border-solid border-flinkGray'
      }
    >
      {trip?.orders?.map((order, idx) => (
        <OrderComponent
          idx={idx}
          key={order?.id}
          order={order}
          trip={trip.orders}
          setMapVisible={setMapVisible}
          onAction={onAction}
          availableActions={{
            isTripCompletable,
            isTripUnassignable,
            isUnstackable,
            isTripAssignable,
          }}
        />
      ))}
      <ModalWrapper isOpen={mapVisible} onClose={() => setMapVisible(false)}>
        <div className="relative z-0">
          <button
            className="color-flinkGray-light absolute top-2 right-2 z-20 flex rounded-lg bg-flinkGray-medium py-2 px-4 text-sm"
            onClick={() => setMapVisible(false)}
          >
            <Icon icon={'eye-hide'} size={'small'} color="currentColor" className="mr-2" />
            {t('order_hide_map')}
          </button>
          <Suspense fallback={<div>...</div>}>
            <TripMap
              orders={trip.orders}
              rider={trip.orders[0]?.rider as Rider}
              activeOrderNumber={activeOrderNumber}
              isRiderTrackingEnabled={trip.orders.some((order) =>
                [
                  StateCodesEnum.RIDERCLAIMED,
                  StateCodesEnum.ONROUTE,
                  StateCodesEnum.ARRIVED,
                  StateCodesEnum.DELIVERED,
                ].includes(order.state as number)
              )}
            />
          </Suspense>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default memo(TripList);
