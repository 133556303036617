const formatNumber = (number?: number, zeroPad = true): string | undefined => {
  if (number === undefined) {
    return undefined;
  }
  return zeroPad ? number.toString().padStart(2, '0') : number.toString();
};

export const convertSecondsToMinutes = (value?: number, zeroPad = true): string | undefined => {
  if (value === undefined) {
    return undefined;
  }
  return formatNumber(Math.floor(value / 60), zeroPad);
};

const convertSecondsToTime = (value?: number): string | undefined => {
  if (value === undefined) {
    return undefined;
  }
  const minutes = convertSecondsToMinutes(value);
  const seconds = formatNumber(value % 60);

  return `${minutes}:${seconds}`;
};

export const formatTimeWithUnits = (value?: number): string | undefined => {
  if (value === undefined) {
    return undefined;
  }

  const minutes = Math.floor(value / 60);
  const seconds = value % 60;

  return `${minutes}m ${seconds}s`;
};

export default convertSecondsToTime;
