import { useEffect, RefObject } from 'react';

// SOURCE
// https://usehooks.com/useOnClickOutside/

const useOnClickOutside = (
  ref: RefObject<Element>,
  ignoreElementRef: RefObject<Element>,
  handler: (T: any) => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (
        !ref.current ||
        ignoreElementRef.current?.contains(event.target as Element) ||
        ref.current.contains(event.target as Element)
      ) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, ignoreElementRef, handler]);
};

export default useOnClickOutside;
