import styled from 'styled-components';

export const PopoverWrapper = styled.div<{ open: boolean }>`
  position: relative;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
`;

export const PopoverContent = styled.div`
  padding: ${({ theme }) => `${theme.sizes['4']} ${theme.sizes['8']}`};
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) => theme.sizes['1']};
  border: ${({ theme }) => `1px solid ${theme.colors.GRAY_LIGHT}`};
  background-color: ${({ theme }) => theme.colors.GRAY};
  box-shadow: ${({ theme }) => `0 1px 2px 0 ${theme.colors.GRAY_DARK}`};
  z-index: 9;
  cursor: auto;
`;
