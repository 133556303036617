import styled from 'styled-components';

type TDashboardHeader = {
  active: boolean;
};

type HubNameProps = {
  active: boolean;
};

export const DashboardHeader = styled.div<TDashboardHeader>`
  background-color: ${({ theme }) => theme.colors.GRAY_MEDIUM};
  margin: ${({ theme }) => `0 2.5rem ${theme.sizes['3']}`};
  ${({ active, theme }) => active && `box-shadow: 0 2px 0 ${theme.colors.GRAY};`}
`;

export const Main = styled.div`
  flex-direction: column;
  position: relative;
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.GRAY_MEDIUM};
`;

export const HubName = styled.h2<HubNameProps>`
  ${({ theme }) => theme.mixins.specialFont()}
  font-size: ${({ active }) => (active ? 1 : 2)}rem;
  font-weight: 700;
  transition: font-size 0.2s;
  margin: ${({ theme, active }) => `${active ? theme.sizes['6'] : theme.sizes['4']} 0`};
`;
