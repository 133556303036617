import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import useStore from 'hooks/useStore';
import { OrderColumnsHeader } from './style';

const Label = ({ children }: { children: string }) => {
  return (
    <span className="inline-block rounded bg-flinkGray px-1 py-2 text-xs text-flinkGray-light">
      {children}
    </span>
  );
};

const OrdersHeader = () => {
  const { t } = useTranslation();
  const isFullScreen = useStore((state) => state.isFullScreen);

  return (
    <OrderColumnsHeader isFullScreen={isFullScreen}>
      <div>
        <Label>{t('order_column_id')}</Label>
      </div>
      <div>
        <Label>{t('order_column_cart_items')}</Label>
      </div>
      {!isFullScreen && (
        <div>
          <Label>{t('order_column_order_value')}</Label>
        </div>
      )}
      <div>
        <Label>{t('order_column_promised_time')}</Label>
      </div>
      <div>
        <Label>{t('order_pickingtime')}</Label>
      </div>
      <div>
        <Label>{t('order_ridetime')}</Label>
      </div>
      <div>
        <Label>{t('order_column_countdown')}</Label>
      </div>
      {!isFullScreen && (
        <div className="text-center">
          <Label>{t('order_column_actions')}</Label>
        </div>
      )}
    </OrderColumnsHeader>
  );
};

export default memo(OrdersHeader);
