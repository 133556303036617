import SDK from '@hubportal/sdk';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client';

export default () => {
  const httpLink = createHttpLink({
    uri: process.env.BFF_URL_API_GATEWAY,
  });

  const authLink = setContext(async (_, { headers }) => {
    const auth0Token = await SDK.getAuthClient().getTokenSilently({
      audience: process.env.BFF_AUDIENCE,
    });

    if (!auth0Token) {
      throw new Error('Authorization missing');
    }

    return {
      headers: {
        ...headers,
        authorization: 'Bearer ' + auth0Token,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        SDK.reportError(new Error(`[GraphQL error]: Message: ${message}`), '@hubportal/orders', {
          message,
          locations,
          path,
          extensions,
        });

        switch (extensions?.code) {
          case 'UNAUTHENTICATED':
          case 'FORBIDDEN':
            SDK.logout();
            break;
        }
      });
    }

    if (networkError) {
      const { response } = operation.getContext();

      if (response?.status === 401) {
        SDK.reportError(
          new Error(`[Network error]: Unauthenticated 401, logging out user`),
          '@hubportal/orders'
        );

        SDK.logout();
      } else {
        SDK.reportError(
          new Error(`[Network error]: Message: ${networkError}`),
          '@hubportal/orders',
          {
            response,
            operation,
          }
        );
      }
    }
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Order: {
        keyFields: ['id', 'orderNumber'],
      },
    },
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 5000,
      max: Infinity,
      jitter: false,
    },
    attempts: {
      max: 5,
    },
  });

  return new ApolloClient({
    link: ApolloLink.from([retryLink, errorLink, authLink.concat(httpLink)]),
    cache,
  });
};
