const QRCodeVisual = () => (
  <svg width="36" height="66" viewBox="0 0 36 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="13.0193" width="33.0013" height="51.9843" rx="4" fill="#30353A" />
    <rect x="33.0013" y="24.9932" width="2.33637" height="10.8057" fill="#E31C79" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 9.5L16 0L18.5 4.61399e-08L18.5 9.5H16Z"
      fill="#D53C76"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.38659 9.72741L7.31604 2L9.73085 1.35295L11.8014 9.08036L9.38659 9.72741Z"
      fill="#D53C76"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2015 9.72741L24.2721 2L26.6869 2.64705L24.6164 10.3745L22.2015 9.72741Z"
      fill="#D53C76"
    />
  </svg>
);

export default QRCodeVisual;
