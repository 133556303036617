import { useEffect, useRef } from 'react';

import useKeyPress from 'hooks/useKeyPress';
import useOutsideClick from 'hooks/useOutsideClick';

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const ModalWrapper = ({ isOpen, onClose, children }: Props) => {
  const ref = useRef(null);
  const escape = useKeyPress('Escape');

  useEffect(() => {
    if (escape) {
      onClose();
    }
  }, [escape, onClose]);

  useEffect(() => {
    // disable background scrolling when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = '';
      };
    }
  }, [isOpen]);

  useOutsideClick(ref, () => {
    if (isOpen) {
      onClose();
    }
  });

  return isOpen ? (
    <div className="fixed inset-0 z-9999 overflow-y-auto" role="dialog" aria-modal="true">
      <div className="flex min-h-screen items-start justify-center pt-4 pb-20">
        <div
          className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"
          aria-hidden="true"
        ></div>
        <div
          ref={ref}
          className="mx-auto mt-40 flex w-9/12 transform flex-col overflow-hidden rounded-lg bg-flinkGray-medium p-4 text-left align-bottom text-white shadow-xl transition-all"
        >
          {children}
        </div>
      </div>
    </div>
  ) : null;
};

export { ModalWrapper };
