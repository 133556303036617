import { useCallback, useEffect, useRef, useState, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import useKeyPress from 'hooks/useKeyPress';
import useOnClickOutside from 'hooks/useOnClickOutside';
import Icon from 'components/Icon/Icon';

export type Option = {
  value: string | number;
  label: string;
};

const CLASS =
  'border-2 h-full border-flinkGray bg-flinkGray-medium text-inherit rounded-lg focus:outline-none focus:ring-0 focus:ring-offset-0';

type Props = {
  name?: string;
  placeholder?: string;
  options: Option[];
  selectedOption?: Option;
  onSelect?: (option: Option) => void;
  isDisabled?: boolean;
  displayValue?: string;
  hasSearch?: boolean;
  isRequired?: boolean;
};

const CustomSelect = ({
  name,
  placeholder,
  isRequired,
  hasSearch,
  options,
  selectedOption,
  onSelect,
  isDisabled,
  displayValue = selectedOption?.label || '',
}: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const escape = useKeyPress('Escape');
  const [isOpen, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchText, setSearchText] = useState<string | null>(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const onClickButton = useCallback(() => {
    if (!hasSearch) {
      setOpen(!isOpen);
    } else if (!isOpen) {
      setSearchText('');
      setFilteredOptions(options);
      setOpen(true);
    }
  }, [hasSearch, isOpen, options]);

  const onClose = useCallback(() => {
    if (hasSearch) {
      setSearchText(null);
      setFilteredOptions(options);
      inputRef.current?.blur();
    }
    setOpen(false);
  }, [hasSearch, options]);

  useEffect(() => {
    if (escape && isOpen) {
      onClose();
    }
  }, [escape, isOpen, onClose]);

  useOnClickOutside(ref, inputRef, () => {
    if (isOpen) {
      onClose();
    }
  });

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (!hasSearch) return;
    setSearchText(e.target.value);
    if (!e.target.value) return setFilteredOptions(options);

    const normalizedValue = e.target.value.trim().toUpperCase();
    setFilteredOptions(
      options.filter((item) => item.label.toUpperCase().includes(normalizedValue))
    );
  };
  const onSelectOption = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: Option
  ) => {
    event.preventDefault();
    onClose();
    onSelect && onSelect(option);
  };

  return (
    <div className={`relative text-[#C4C4C4]`} ref={ref}>
      <button
        className={`relative cursor-default pl-2 ${CLASS}`}
        onClick={onClickButton}
        disabled={isDisabled || !options.length}
        type="button"
      >
        <input
          ref={inputRef}
          className={`placeholder-gray-light block w-full cursor-default truncate border-0 py-3 pr-8 text-left text-base outline-none ring-0 ring-offset-0 disabled:cursor-not-allowed ${
            !hasSearch ? 'caret-transparent' : ''
          } ${CLASS}`}
          value={searchText ?? displayValue}
          onChange={onSearch}
          disabled={isDisabled || !options.length}
          name={name}
          placeholder={placeholder}
          required={isRequired}
        />
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 opacity-20">
          <Icon icon={isOpen ? 'chevron-up' : 'chevron-down'} size="large" color="currentColor" />
        </span>
      </button>
      {isOpen && (
        <ul className="absolute z-10 max-h-56 w-full overflow-auto rounded-md border-0 bg-flinkGray py-1 text-sm">
          {filteredOptions?.length ? (
            filteredOptions.map((option: Option) => (
              <li key={option.value} className="hover:bg-flinkGray-medium">
                <button
                  className="my-0.5 flex w-full cursor-default items-center justify-between border-b-0 p-2 text-base hover:bg-flinkGray-medium"
                  onClick={(event) => onSelectOption(event, option)}
                  type="button"
                >
                  <span className="ml-3 block truncate" data-testid={`option-${option.label}`}>
                    {option.label}
                  </span>
                  {selectedOption?.value === option.value && (
                    <span className="text-flinkPink">
                      <Icon icon={'check'} size={'small'} color="currentColor" />
                    </span>
                  )}
                </button>
              </li>
            ))
          ) : (
            <li className="p-2 text-center">{t('noOptions')}</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
