import styled from 'styled-components';

import Icon from 'components/Icon/Icon';

export const Input = styled.input`
  display: block;
  ${({ theme }) => theme.mixins.formElement()}
  color: ${({ theme }) => theme.colors.WHITE};
  padding: ${({ theme }) => theme.sizes['4']};
`;

export const SearchIcon = styled(Icon).attrs({
  icon: 'search',
  size: 'medium',
})``;

export const CloseIcon = styled(Icon).attrs({
  icon: 'close',
  size: 'medium',
})``;

export const FilterInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
  width: 100%;
  border-radius: ${({ theme }) => theme.sizes['2']};
  padding-right: ${({ theme }) => theme.sizes['4']};
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.GRAY};
`;
