const backToTopHandle = () => {
  const appElement = document.getElementById('orders-module');

  if (appElement) {
    appElement.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

export default backToTopHandle;
