import { memo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { InfoProps } from './types';

const InfoFC = ({
  refElement,
  type = 'success',
  authoHideDuration = 0,
  onClose,
  children,
}: React.PropsWithChildren<InfoProps>): JSX.Element => {
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (authoHideDuration) {
      timeout = setTimeout(() => {
        onClose && onClose();
      }, authoHideDuration);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const infoContent = (
    <div
      className={`p-2 text-start ${
        type === 'success' ? 'bg-teal-100 text-gray-800' : 'bg-pink-200 text-red-700'
      }`}
    >
      {children}
    </div>
  );

  if (refElement) {
    return createPortal(infoContent, refElement);
  }
  return infoContent;
};

const Info = memo(InfoFC);
export { Info };
