import * as colors from './colors';
import sizes from './sizes';

//  Typography Mixins
export const genericFont = () => {
  return `
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: normal;
  `;
};

export const specialFont = () => {
  return `
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: normal;
  `;
};

export const text = () => {
  return `
    ${genericFont()}
    font-size: 1rem;
  `;
};

export const textBold = () => {
  return `
    ${genericFont()}
    font-size: 1rem;
    font-weight: bold;
  `;
};

export const textSmall = () => {
  return `
    ${genericFont()}
    font-size: 0.875rem;
  `;
};

// Input Element Mixins
export const formElement = () => {
  return `
    ${text()}
    display: block;
    width: 100%;
    appearance: none;
    border: none;
    background: none;
    padding: 0 1rem 0.5rem;

    &::placeholder {
      color: ${colors.GRAY_LIGHT};
    }

    &:focus {
      outline: none;
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px ${colors.WHITE} inset; // remove default blue background
      border-radius: ${sizes['2']};
    }
  `;
};
