import compareDatesToRefresh from 'lib/compareDatesToRefresh';
import { useCallback, useEffect, useState } from 'react';

const pageMountedDate = new Date();
const RECHECK_COMPARE_DATES_MIN = 60000 * 30; // check every 30 min

const useRefresh = (seconds = RECHECK_COMPARE_DATES_MIN) => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const compareDates = useCallback(() => {
    const shouldPageRefresh = compareDatesToRefresh(pageMountedDate, currentTime);

    if (shouldPageRefresh) {
      window.location.reload();
    }
  }, [currentTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
      compareDates();
    }, seconds);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, compareDates]);
};

export default useRefresh;
