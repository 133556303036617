import { useEffect, useCallback, useMemo, ChangeEvent, memo, useRef, useContext } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import userContext from 'context/userContext';

import { FilterInputContainer, Input, SearchIcon, CloseIcon } from './style';
import useStore from 'hooks/useStore';
import useKeyPress from 'hooks/useKeyPress';

type Props = {
  hasQuery: boolean;
};

const DEBOUNCE_IN_MS = 250;

const FilterInput = ({ hasQuery }: Props) => {
  const setSearchQuery = useStore((state) => state.setSearchQuery);
  const { selectedHub } = useContext(userContext);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const escape = useKeyPress('Escape');
  const searchPlaceholder = selectedHub?.slug
    ? t('dashboard_search_for') +
      ` "${selectedHub.slug.split('_', 2).join('-').toUpperCase() + '-XXXX-XXXX'}"`
    : t('searchfield_placeholder');

  const debounceQuery = useMemo(() => {
    return debounce((value: string) => {
      setSearchQuery(value);
    }, DEBOUNCE_IN_MS);
  }, [setSearchQuery]);

  const handleInputQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      debounceQuery(event.target.value);
    },
    [debounceQuery]
  );

  const clearInput = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
    setSearchQuery('');
  }, [setSearchQuery]);

  useEffect(() => {
    if (escape) {
      clearInput();
    }
  }, [clearInput, escape]);

  return (
    <FilterInputContainer>
      <Input
        type="text"
        placeholder={searchPlaceholder}
        onChange={handleInputQuery}
        ref={inputRef}
      />

      {hasQuery ? (
        <span onClick={clearInput}>
          <CloseIcon />
        </span>
      ) : (
        <SearchIcon />
      )}
    </FilterInputContainer>
  );
};

export default memo(FilterInput);
