import Icon from 'components/Icon/Icon';

export const StepperWarningBar = ({
  show,
  label,
  tooltip,
  isHubLevelViewActive,
}: {
  show: boolean;
  label: string;
  tooltip?: string;
  isHubLevelViewActive?: boolean;
}) => {
  if (!show) return null;
  return (
    <div
      className={`group relative flex items-center justify-center gap-2 rounded-t bg-orange-400 text-flinkGray ${
        isHubLevelViewActive ? 'h-8 text-sm' : 'h-10'
      }`}
    >
      <Icon icon="caution" size="small" color="currentColor" />
      {label}
      {tooltip && (
        <div className="pointer-events-none invisible absolute -bottom-16 w-56 group-hover:visible group-hover:z-50">
          <div className="relative flex items-center justify-center rounded-lg bg-flinkPink-light px-4 py-2 text-center text-xs">
            {tooltip}
            <div className="absolute -top-1.5 h-4 w-4 rotate-45 bg-flinkPink-light" />
          </div>
        </div>
      )}
    </div>
  );
};
