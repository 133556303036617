import React from 'react';

import { LocalesEnum } from 'lib/constants';
import { CountryT, LocaleT } from '@hubportal/sdk';
import { Hub } from 'api/generated';

type SetterT<T> = (e: T) => void;

export type ContextProps = {
  country: CountryT | null;
  setCountry: SetterT<CountryT>;
  selectedHub: Hub | null;
  setSelectedHub: SetterT<Hub>;
  locale: LocaleT;
  setLocale: SetterT<LocalesEnum>;
};

export const initialContext = {
  country: null,
  setCountry: () => {},
  selectedHub: {},
  setSelectedHub: () => {},
  locale: 'en' as LocaleT,
  setLocale: () => {},
};

const AppContext = React.createContext<ContextProps>(initialContext);

export default AppContext;
