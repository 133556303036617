import { memo, useMemo } from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import { SelectItem, SelectVariant, InputWithDropdown } from '@hubportal/components';
import { useSearchRidersLazyQuery } from 'api/generated';

const DEBOUNCE_DELAY = 300;

export type RiderOption = { id: string; label: string | number } | null;
type Props = {
  selectedOption: RiderOption;
  onSelect: (rider: RiderOption) => void;
  slug: string;
};

const DynamicSelect = ({ onSelect, selectedOption, slug }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [searchRiders, { data: riders }] = useSearchRidersLazyQuery({
    fetchPolicy: 'network-only',
  });

  const debouncedSearch = useMemo(
    () =>
      debounce((event) => {
        if (event.target.value.trim().length >= 2) {
          searchRiders({ variables: { query: event.target.value, slug } });
        }
      }, DEBOUNCE_DELAY),
    [searchRiders, slug]
  );

  const onSelectNewOption = (nextValue: RiderOption) => {
    if (selectedOption?.id !== nextValue?.id || nextValue === null) {
      onSelect(nextValue);
    } else {
      onSelect(null);
    }
  };

  return (
    <div className="relative w-60">
      <InputWithDropdown
        variant={SelectVariant.primary}
        onSearch={debouncedSearch}
        onSelect={onSelectNewOption}
        placeholder={selectedOption ? (selectedOption.label as string) : t('rider_filter')}
        value={selectedOption}
      >
        {!!riders?.searchRiders?.length
          ? riders?.searchRiders.map((rider) => (
              <SelectItem
                key={rider.id}
                id={rider.id as string}
                label={`${rider.firstName} ${rider.lastName}`}
                variant={SelectVariant.primary}
              >
                {rider.firstName} {rider.lastName}
              </SelectItem>
            ))
          : []}
      </InputWithDropdown>
    </div>
  );
};

const areEqual = (prev: Props, next: Props) => isEqual(prev.selectedOption, next.selectedOption);

export default memo(DynamicSelect, areEqual);
