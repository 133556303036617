import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

const useShowScrollTop = (): [RefObject<HTMLDivElement>, boolean] => {
  const [scrollToTopIsVisible, setScrollToTopIsVisible] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);

  const scrollHandler = useCallback(() => {
    if (headerRef.current && headerRef.current?.getBoundingClientRect().y < 248) {
      setScrollToTopIsVisible(true);
    } else {
      setScrollToTopIsVisible(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  return [headerRef, scrollToTopIsVisible];
};

export default useShowScrollTop;
