type Props = {
  label: string;
  bgColor?: string;
  textColor?: string;
};

const Badge = ({ label, bgColor = 'bg-blue-400', textColor = 'text-black' }: Props) => (
  <div className={`select-none rounded ${bgColor} px-1 py-0.5 text-sm font-medium ${textColor}`}>
    {label}
  </div>
);

export default Badge;
