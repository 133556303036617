import { useTranslation } from 'react-i18next';
import { useMemo, memo } from 'react';
import isEqual from 'lodash/isEqual';

import FilterInput from 'components/FilterInput';
import useStore from 'hooks/useStore';

type Props = {
  resultCount: number;
};

const Filter = ({ resultCount = 0 }: Props) => {
  const searchQuery = useStore((state) => state.searchQuery);
  const { t } = useTranslation();
  const hasQuery = searchQuery.length > 1;

  const status = useMemo(() => {
    if (resultCount && hasQuery) {
      return `${t('filter_matches', { count: resultCount })} "${searchQuery}"`;
    } else if (!resultCount && hasQuery) {
      return t('filter_nomatch');
    } else {
      return '';
    }
  }, [resultCount, hasQuery, t, searchQuery]);

  return (
    <div className="top-0 z-10 flex h-15 w-full min-w-120 flex-col">
      <FilterInput hasQuery={hasQuery} />

      {status && (
        <p data-testid="filter__status" className="pt-0.5 pl-2">
          {status}
        </p>
      )}
    </div>
  );
};

export default memo(Filter, isEqual);
