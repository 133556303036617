export enum LocalesEnum {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  NL = 'nl',
}

export enum RiderStates {
  'ONLINE' = 'ONLINE',
  'OFFLINE' = 'OFFLINE',
  'BUSY' = 'BUSY',
  'RETURNING' = 'RETURNING',
  'STARTING' = 'STARTING',
  'IDLE' = 'IDLE',
  'ON_BREAK' = 'ON_BREAK',
  'TEMP_OFFLINE' = 'TEMP_OFFLINE',
}

export enum StateCodesEnum {
  CREATED = 1, // Supported in saleor
  PICKERACCEPTED = 2, // Supported in saleor
  PACKED = 3, // Supported in saleor
  RIDERCLAIMED = 4,
  ONROUTE = 5,
  ARRIVED = 6, // Supported in saleor
  DELIVERED = 7, // Supported in saleor
  CANCELLED = 8,
  UNSPECIFIED = 9,
}

export enum ROUTES {
  index = '/',
  hub = '/hub',
  dashboard = '/dashboard',
}

export enum TripActionModalType {
  UNSTACK = 'unstack',
  UNASSIGN_RIDER = 'unassign_rider',
  ASSIGN_RIDER = 'assign_rider',
  COMPLETE_TRIP = 'complete',
}

export enum TripActionType {
  MARK_FORCE_COMPLETE = 'mark_force_complete',
  REJECT = 'reject',
}

export const locales = [
  { name: 'English', value: LocalesEnum.EN },
  { name: 'Deutsch', value: LocalesEnum.DE },
  { name: 'Français', value: LocalesEnum.FR },
  { name: 'Nederlands', value: LocalesEnum.NL },
];

export const countries = [
  { code: 'AT', name: 'Austria' },
  { code: 'DE', name: 'Germany' },
  { code: 'FR', name: 'France' },
  { code: 'NL', name: 'Netherlands' },
];

type StatusLabelsT = Array<{ number: number; description: string | null }>;

export const statusLabels: StatusLabelsT = [
  {
    number: StateCodesEnum.CREATED,
    description: 'order_state_neworder',
  },
  {
    number: StateCodesEnum.PICKERACCEPTED,
    description: 'order_state_packing',
  },
  {
    number: StateCodesEnum.PACKED,
    description: 'order_state_readytodeliver',
  },
  {
    number: StateCodesEnum.RIDERCLAIMED,
    description: 'order_status_claimed',
  },
  {
    number: StateCodesEnum.ONROUTE,
    description: 'order_state_enroute',
  },
  {
    number: StateCodesEnum.ARRIVED,
    description: 'order_state_arrived',
  },
  {
    number: StateCodesEnum.DELIVERED,
    description: 'order_state_delivered',
  },
  {
    number: StateCodesEnum.CANCELLED,
    description: 'order_state_cancelled',
  },
  {
    number: StateCodesEnum.UNSPECIFIED,
    description: null,
  },
];

type RiderStateMap = { [key in RiderStates]: string };

export const RiderStateLabels: RiderStateMap = {
  [RiderStates.ONLINE]: 'rider_state_labels_online',
  [RiderStates.OFFLINE]: 'rider_state_labels_offline',
  [RiderStates.BUSY]: 'rider_state_labels_busy',
  [RiderStates.RETURNING]: 'rider_state_labels_returning',
  [RiderStates.STARTING]: 'rider_state_labels_starting',
  [RiderStates.IDLE]: 'rider_state_labels_idle',
  [RiderStates.ON_BREAK]: 'rider_state_labels_on_break',
  [RiderStates.TEMP_OFFLINE]: 'rider_state_labels_temp_offline',
};

export const RiderStateColors: RiderStateMap = {
  [RiderStates.ONLINE]: 'bg-[#59BEAA]',
  [RiderStates.OFFLINE]: 'bg-[#D1D5DB]',
  [RiderStates.BUSY]: 'bg-[#ED6BA7]',
  [RiderStates.RETURNING]: 'bg-[#FCE7F1]',
  [RiderStates.STARTING]: 'bg-[#ABABAB]',
  [RiderStates.IDLE]: 'bg-[#ABABAB]',
  [RiderStates.ON_BREAK]: 'bg-[#ABABAB]',
  [RiderStates.TEMP_OFFLINE]: 'bg-[#ABABAB]',
};

export const themeModes = ['light', 'dark'];

export type RiderWithStatus = {
  id: string;
  status: RiderStates;
  statusReason?: string;
  firstName: string;
  lastName: string;
  activeOrderNumber?: string;
};

export type RiderLocation = {
  lat?: number;
  lng?: number;
  latitude?: number;
  longitude?: number;
  eta?: number;
};

export type RiderWithStatusAndLocation = RiderWithStatus & RiderLocation;

export enum AddressTag {
  WORK = 'work',
  OUTDOOR = 'outdoor',
}
