import create from 'zustand';

type Store = {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
};

const useStore = create<Store>()((set) => ({
  isFullScreen: false,
  toggleFullScreen: () => set((state) => ({ isFullScreen: !state.isFullScreen })),
  searchQuery: '',
  setSearchQuery: (query) => set(() => ({ searchQuery: query.trim() })),
}));

export default useStore;
