import { Info } from 'components/Info/Info';

export const WarningBar = ({
  labels,
  onClickDetails,
}: {
  labels: { title: string; details: string };
  onClickDetails: () => void;
}) => {
  return (
    <div className="mt-4">
      <Info type="error">
        <div className="flex items-center justify-between">
          <div className="font-flink text-flinkGray-medium">{labels.title}</div>
          <p
            className="cursor-pointer text-sm font-medium text-flinkPink underline"
            onClick={onClickDetails}
          >
            {labels.details}
          </p>
        </div>
      </Info>
    </div>
  );
};
