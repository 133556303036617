export const Stepper = ({
  children,
  isHubLevelViewActive,
}: {
  children: React.ReactNode;
  isHubLevelViewActive: boolean;
}) => {
  return (
    <div className={`flex gap-4 text-center ${isHubLevelViewActive ? 'px-6 py-2' : 'p-4'}`}>
      {children}
    </div>
  );
};

const COLOR_PALETTE = {
  pink: 'text-flinkPink-medium',
  blue: 'text-blue-400',
  orange: 'text-orange-400',
  default: 'text-flinkGray-light',
} as const;

export const Step = ({
  count,
  label,
  minimized,
  variant = 'default',
  children,
  isHubLevelViewActive,
  isOutlinedBadgeExist = false,
}: {
  minimized: boolean;
  count: number;
  label: string;
  variant?: keyof typeof COLOR_PALETTE;
  children?: React.ReactNode;
  isHubLevelViewActive: boolean;
  isOutlinedBadgeExist?: boolean;
}) => {
  return (
    <div
      className={`flex gap-x-4 [&:not(:last-child):after]:h-px [&:not(:last-child):after]:flex-1 [&:not(:last-child):after]:bg-flinkGray-light [&:not(:last-child):after]:content-[''] [&:not(:last-child)]:flex-1 ${
        isHubLevelViewActive
          ? isOutlinedBadgeExist
            ? '[&:not(:last-child):after]:mt-8'
            : '[&:not(:last-child):after]:mt-4'
          : minimized
          ? '[&:not(:last-child):after]:mt-4'
          : '[&:not(:last-child):after]:mt-6'
      }`}
    >
      <div
        className={`flex flex-col ${isHubLevelViewActive ? 'justify-center gap-y-1' : ' gap-y-2'}`}
      >
        <div
          className={`flex ${
            isHubLevelViewActive
              ? 'flex-row items-center gap-x-0.5 text-left'
              : minimized
              ? 'flex-row items-baseline gap-x-2'
              : 'flex-col items-center gap-x-2'
          } ${COLOR_PALETTE[variant]} `}
        >
          <div
            className={`transition-[font-size] duration-200 ${
              isHubLevelViewActive ? 'header-m mr-2' : minimized ? 'header-s' : 'header-l'
            } ${variant === 'default' ? 'text-white' : ''}`}
          >
            {count}
          </div>
          <div
            className={
              isHubLevelViewActive
                ? isOutlinedBadgeExist
                  ? 'title-s text-wrap w-16'
                  : 'title-s'
                : 'title-m'
            }
          >
            {label}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
