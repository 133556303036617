import styled from 'styled-components';
import { StateCodesEnum } from '../../lib/constants';

export const Columns = styled.div<{ isFullScreen?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isFullScreen }) =>
    isFullScreen
      ? 'minmax(220px, 2fr) repeat(4, minmax(100px, 1fr)) 1fr'
      : 'minmax(220px, 2fr) repeat(5, minmax(100px, 1fr)) minmax(175px, 1fr) minmax(50px, 1fr)'};
  align-items: center;
`;

export const OrderWrapper = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.GRAY_MEDIUM};
`;

export const OrderColumnsHeader = styled(Columns)`
  padding-bottom: ${({ theme }) => theme.sizes[2]};
  padding-left: ${({ theme }) => theme.sizes[2]};
  padding-right: ${({ theme }) => theme.sizes[2]};
`;

export const OrderColumns = styled(Columns)<{ state: number }>`
  ${({ state, theme }) =>
    state === StateCodesEnum.PACKED && ` h2, span { color: ${theme.colors.WHITE} }`}
`;

export const OrderId = styled.h2`
  font-size: 1.15rem;
  line-height: 1;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0 0 0.25rem 0;
  color: ${({ theme }) => theme.colors.GRAY_LIGHT};
`;

export const OrderDate = styled.span`
  display: block;
  ${({ theme }) => theme.typography.sm};
  color: ${({ theme }) => theme.colors.GRAY_LIGHT};
  margin-bottom: ${({ theme }) => theme.sizes[1.5]};
`;

export const ColumnItem = styled.div`
  font-size: 17px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.GRAY_LIGHT};
`;

export const Byline = styled.span`
  ${({ theme }) => theme.typography.sm};
  color: ${({ theme }) => theme.colors.GRAY_LIGHT};
`;

export const Pill = styled.span`
  display: inline-flex;
  align-items: center;
  margin: 0.25rem 0.25rem 0 0;
  ${({ theme }) => theme.typography.sm};
  background-color: ${({ theme }) => theme.colors.GRAY_DARK};
  border-radius: 4px;
  padding: 5px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.WHITE};

  .icon {
    margin-right: 0.25rem;
  }
`;

type IColoredPill = {
  color: string;
};

export const ColoredPill = styled(Pill)<IColoredPill>`
  background-color: ${({ theme, color }) => theme.colors[color]};
`;
