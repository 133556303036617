import { memo, MutableRefObject, useState } from 'react';
import { type ListTrip } from 'utils/trips';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import TripList from 'components/Trip/TripList';
import AssignRiderModal from 'components/AssignRiderModal';

import { TripActionModalType, TripActionType } from 'lib/constants';
import { randomIntFromInterval } from 'lib/orderHelpers';
import { useUnstackTripMutation, useTripActionMutation, Order } from 'api/generated';
import { Info } from 'components/Info/Info';

type Props = {
  tripActionMessagesRef?: MutableRefObject<null>;
  trips: ListTrip[];
  showAllOrders: boolean;
  slug?: string;
  isAllowedToUpdate: boolean;
};

type TripActionModalProps = {
  isOpen: boolean;
  label: string;
  error?: string;
  onCancel: () => void;
  onConfirm: () => void;
  orders?: Order[];
};

const TripActionModal = ({
  isOpen,
  label,
  error,
  onCancel,
  onConfirm,
  orders,
}: TripActionModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      submitText={t('confirm')}
      cancelText={t('cancel')}
      onConfirm={onConfirm}
    >
      <div className="px-6 text-center">
        <h2 className="text-lg font-bold">{label}</h2>
        <p className="flex flex-wrap justify-center py-3 text-sm">
          {orders?.map((order) => (
            <span className="mx-3 rounded bg-flinkGray p-3" key={order.id}>
              {order.orderNumber?.toUpperCase()}
            </span>
          ))}
        </p>
        {error && (
          <div className="pb-3">
            <Info type="error">{t(error)}</Info>
          </div>
        )}
      </div>
    </Modal>
  );
};

const OrdersList = ({
  tripActionMessagesRef,
  trips,
  showAllOrders,
  slug,
  isAllowedToUpdate,
}: Props) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<TripActionModalType | null>(null);
  const [modalOrders, setModalOrders] = useState<Order[]>([]);
  const [tripActionError, setTripActionError] = useState('');
  const [tripActionSuccess, setTripActionSuccess] = useState('');

  const [unstackTripMutation] = useUnstackTripMutation({
    onCompleted: () => {
      resetModalState();
      setTripActionSuccess('order_action_unstack_success');
    },
    onError: (error) => {
      setTripActionError(error.message);
    },
  });

  const [tripActionMutation] = useTripActionMutation({
    onCompleted: () => {
      const message =
        modal === TripActionModalType.UNASSIGN_RIDER
          ? 'order_action_unassign_success'
          : modal === TripActionModalType.COMPLETE_TRIP
          ? 'order_action_force_complete_success'
          : 'error';

      resetModalState();
      setTripActionSuccess(message);
    },
    onError: (error) => {
      setTripActionError(t(error.message));
    },
  });

  const unstackOrder = () => {
    setTripActionError('');
    const orderIds = (modalOrders || []).map((o) => o.id) as string[];
    unstackTripMutation({
      variables: {
        hub: slug as string,
        orders: orderIds,
      },
    });
  };

  const unassignRider = () => {
    setTripActionError('');
    tripActionMutation({
      variables: {
        riderId: modalOrders[0].riderId || '',
        tripId: modalOrders[0].trackingId || '',
        tripActionType: TripActionType.REJECT,
      },
    });
  };

  const completeTrip = () => {
    setTripActionError('');
    tripActionMutation({
      variables: {
        riderId: modalOrders[0].riderId || '',
        tripId: modalOrders[0].trackingId || '',
        tripActionType: TripActionType.MARK_FORCE_COMPLETE,
      },
    });
  };

  const onAction = (actionType: TripActionModalType, orders: Order[]) => {
    setModal(actionType);
    setModalOrders(orders);
  };

  const resetModalState = () => {
    setModal(null);
    setTripActionError('');
    setModalOrders([]);
  };

  const renderTripActionModal = () => {
    switch (modal) {
      case TripActionModalType.UNSTACK:
        return (
          <TripActionModal
            label={t('unstack_trip_modal_title')}
            error={tripActionError}
            isOpen={modal === TripActionModalType.UNSTACK}
            onCancel={resetModalState}
            onConfirm={unstackOrder}
            orders={modalOrders}
          />
        );
      case TripActionModalType.COMPLETE_TRIP:
        return (
          <TripActionModal
            label={t('complete_trip_modal_title')}
            error={tripActionError}
            isOpen={modal === TripActionModalType.COMPLETE_TRIP}
            onCancel={resetModalState}
            onConfirm={completeTrip}
            orders={modalOrders}
          />
        );
      case TripActionModalType.UNASSIGN_RIDER:
        return (
          <TripActionModal
            label={t('unassign_rider_modal_title')}
            error={tripActionError}
            isOpen={modal === TripActionModalType.UNASSIGN_RIDER}
            onCancel={resetModalState}
            onConfirm={unassignRider}
            orders={modalOrders}
          />
        );
      case TripActionModalType.ASSIGN_RIDER:
        return (
          <AssignRiderModal
            isOpen={modal === TripActionModalType.ASSIGN_RIDER}
            onCancel={resetModalState}
            onSuccess={() => {
              resetModalState();
              setTripActionSuccess('order_action_assign_success');
            }}
            data={{
              slug: slug as string,
              orderIds: modalOrders.map((order) => order.id) as string[],
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderTripActionModal()}
      {tripActionSuccess && (
        <Info
          refElement={tripActionMessagesRef?.current}
          type="success"
          authoHideDuration={4000}
          onClose={() => setTripActionSuccess('')}
        >
          {t(tripActionSuccess)}
        </Info>
      )}
      {trips?.map(
        (trip) =>
          (!trip.heldBackFromPicking || showAllOrders) && (
            <TripList
              key={
                trip?.orders?.length > 0
                  ? trip?.orders[0]?.id
                  : `trip-${randomIntFromInterval(1, 1000)}`
              }
              trip={trip}
              onAction={onAction}
              isAllowedToUpdate={isAllowedToUpdate}
            />
          )
      )}
    </>
  );
};

export default memo(OrdersList);
