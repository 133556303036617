import { useMemo, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import userContext from 'context/userContext';

import Modal from 'components/Modal';
import Icon from 'components/Icon/Icon';
import { Info } from 'components/Info/Info';

import { GRAY_LIGHT } from 'theme/colors';
import { Rider, useAssignRiderMutation, useSearchRidersLazyQuery } from 'api/generated';
import { RiderStates } from 'lib/constants';
import useOrders from 'hooks/useOrders';

const DEBOUNCE_DELAY = 300;

type Data = {
  slug: string;
  orderIds: string[];
};

export type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  data: Data;
};

const findExactMatchingOrderIdsByOrderIds = (
  trips: { orderIds: string[] }[],
  searchOrderIds: string[]
): string[] | false => {
  const searchOrderSet = new Set(searchOrderIds);

  for (const trip of trips) {
    const tripOrderSet = new Set(trip.orderIds);

    if (
      tripOrderSet.size === searchOrderSet.size &&
      [...tripOrderSet].every((orderId) => searchOrderSet.has(orderId))
    ) {
      return trip.orderIds;
    }
  }
  return false;
};

const AssignRiderModal = ({ isOpen, onCancel, onSuccess, data }: Props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const [riderId, setRiderId] = useState<string | undefined>();
  const { selectedHub } = useContext(userContext);
  const [isTripMatchError, setIsTripMatchError] = useState(false);
  const [orderIds, setOrderIds] = useState<string[]>([]);

  const { trips, loading: tripsLoading } = useOrders({
    slug: selectedHub?.slug ?? '',
    interval: 0,
  });

  const [getOnlineRiders, { data: riders, loading }] = useSearchRidersLazyQuery({
    variables: { slug: data.slug, status: RiderStates.ONLINE },
    fetchPolicy: 'network-only',
  });

  const [assignRider, { error, loading: assigning }] = useAssignRiderMutation({
    onCompleted: onSuccess,
  });

  const onlineRiders = (riders?.searchRiders ?? []) as Rider[];

  useEffect(() => {
    getOnlineRiders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearch = useMemo(
    () =>
      debounce((event) => {
        setQuery(event.target.value);
        getOnlineRiders({
          variables: {
            slug: data.slug,
            status: RiderStates.ONLINE,
            query: event.target.value as string,
          },
        });
      }, DEBOUNCE_DELAY),
    [data.slug, getOnlineRiders]
  );

  const renderRiderList = () => {
    if (onlineRiders.length) {
      return onlineRiders.map((rider) => (
        <li
          key={rider.id}
          data-testid="list-item"
          className="border-b-2 border-flinkGray border-opacity-40 hover:bg-flinkGray-medium"
        >
          <label className="flex items-center gap-2 overflow-hidden p-4">
            <input
              type="radio"
              id={rider.id as string}
              value={rider.id as string}
              name="rider"
              className="flex-shrink-0 rounded-sm bg-flinkGray checked:bg-flinkPink checked:hover:bg-flinkPink checked:focus:bg-flinkPink"
              onChange={(event) => setRiderId(event.target.id)}
              checked={riderId === rider.id}
            />
            <div className="ml-3">
              <p className="truncate">
                {rider.firstName} {rider.lastName}
              </p>
              <p className="truncate text-xs text-flinkGray-light text-opacity-60">{rider.email}</p>
            </div>
          </label>
        </li>
      ));
    }
    return (
      <li className="ml-1 border-b-2 border-flinkGray border-opacity-40 p-4 pl-12">
        {loading
          ? '...'
          : query.length
          ? t('assign_rider_no_match')
          : t('assign_rider_none_online')}
      </li>
    );
  };

  useEffect(() => {
    if (trips && data.orderIds) {
      const orderIds = findExactMatchingOrderIdsByOrderIds(trips, data.orderIds);
      if (orderIds) {
        setIsTripMatchError(false);
        setOrderIds(orderIds);
      } else {
        setIsTripMatchError(true);
        setOrderIds([]);
      }
    }
  }, [trips, data.orderIds]);

  const onConfirm = () => {
    if (!orderIds.length) {
      return;
    }
    assignRider({
      variables: {
        orderIds: orderIds,
        riderId: riderId as string,
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      submitText={t('assign_rider_confirm')}
      cancelText={t('cancel')}
      onConfirm={onConfirm}
      isDisabled={!riderId || assigning || tripsLoading || isTripMatchError}
    >
      <div className="z-50 px-6 pb-6">
        <div className="mb-3 text-left">
          <h2 className="mb-6 text-center text-lg">{t('assign_rider_title')}</h2>
          <div className="relative rounded border border-flinkGray-dark border-opacity-80 bg-flinkGray-dark bg-opacity-40 p-6">
            {isTripMatchError && (
              <div className="rounded bg-red-600 p-2 text-xs text-white">
                {t('assign_rider_trip_match_error')}
              </div>
            )}

            <label className="relative mt-1 block">
              <Icon
                icon="search"
                color={GRAY_LIGHT}
                size="medium"
                className="absolute left-3 top-3"
              />
              <input
                className="w-full rounded-md border-none bg-flinkGray p-4 pl-12 text-sm text-white outline-none placeholder:text-flinkGray-light placeholder:opacity-60 focus:ring-transparent"
                placeholder={t('assign_rider_search_placeholder')}
                onChange={debouncedSearch}
                data-testid="search"
              />
            </label>
            <div className="mt-1 h-72 overflow-auto">
              <ul className="w-full text-sm">{renderRiderList()}</ul>
            </div>
          </div>
        </div>
        {error?.message && (
          <div className="pb-3">
            <Info type="error">{t(error.message)}</Info>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AssignRiderModal;
