import { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import {
  ExternalProvider,
  Order,
  OutsourcingProviderName,
  ShippingMethod,
} from '@hub-orders-bff/schema/types';

import { AddressTag, TripActionModalType } from 'lib/constants';
import UserContext from 'context/userContext';
import Icon from 'components/Icon/Icon';
import Panel from 'components/Panel/Panel';

import {
  OrderWrapper,
  OrderColumns,
  OrderId,
  ColumnItem,
  ColoredPill,
  Byline,
  OrderDate,
  Pill,
} from './style';
import TripActions from './TripActions';
import OrderBadge from './OrderBadge';
import useStore from 'hooks/useStore';
import { convertSecondsToMinutes } from 'lib/convertSecondsToTime';
import { dateLocale, formatPromisedTime } from 'lib/orderHelpers';

type Props = {
  idx: number;
  order: Order;
  trip: Order[];
  setMapVisible: (isVisible: boolean) => void;
  availableActions?: {
    isTripCompletable?: boolean;
    isTripUnassignable?: boolean;
    isUnstackable?: boolean;
    isTripAssignable?: boolean;
  };
  onAction: (actionType: TripActionModalType, data: Order[]) => void;
};

const OrderComponent = ({ idx, order, trip, setMapVisible, availableActions, onAction }: Props) => {
  const { t } = useTranslation();
  const actionsRef = useRef(null);
  const isFullScreen = useStore((state) => state.isFullScreen);

  const { locale } = useContext(UserContext);
  const [openPanel, setOpenPanel] = useState(false);
  const [actionsPopoverOpen, setActionsPopoverOpen] = useState(false);

  if (!order) {
    return null;
  }

  const formattedCreatedAt = order.createdAt
    ? format(new Date(order.createdAt), 'eee, d LLL y, p', { locale: dateLocale(locale) })
    : '';

  const formattedRideTime = order.rideDuration
    ? `${convertSecondsToMinutes(order.rideDuration)} min`
    : '';

  const formattedPrice = () => {
    const amount = order.totalPrice?.amount as number;
    const currency = order.totalPrice?.currency as string;

    if (amount && currency) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
      });
      return formatter.format(amount / 100);
    }
    return '';
  };

  const formattedPDT = formatPromisedTime(order, locale);

  const actionsEnabled = idx === 0 && order.shippingMethod !== ShippingMethod.ClickAndCollect;

  return (
    <OrderWrapper>
      <div
        className="cursor-pointer py-3 px-3"
        data-testid="order"
        onClick={() => {
          setMapVisible(false);
          setOpenPanel(!openPanel);
        }}
      >
        <OrderColumns state={order.state ?? 0} isFullScreen={isFullScreen}>
          <ColumnItem>
            <OrderDate data-testid="order-created-at">{formattedCreatedAt}</OrderDate>
            <OrderId data-testid="order-id">{order.orderNumber}</OrderId>
            <Byline data-testid="order-shipping-address">
              {[order.shippingAddress?.postalCode, order.shippingAddress?.streetName]
                .filter((s) => s)
                .join(', ')}
              {order.shippingAddress?.streetNumber}
            </Byline>
            <div className="flex">
              {order.customer?.isNewCustomer && (
                <ColoredPill color="FLINK_PINK">
                  <Icon icon={'star'} size={'smaller'} /> {t('order_new_customer')}
                </ColoredPill>
              )}
              {order.shippingMethod === ShippingMethod.ClickAndCollect && (
                <Pill>
                  <Icon icon="clickandcollect" size="smaller" /> {t('click_and_collect')}
                </Pill>
              )}
              {order.shippingMethod === ShippingMethod.InStorePayment && (
                <Pill>
                  <Icon icon="card" size="smaller" /> {t('in_store')}
                </Pill>
              )}
              {order.externalProvider === ExternalProvider.UberEatsCarrefour && (
                <ColoredPill color="CARREFOUR_BLUE">
                  <Icon icon="carrefour-badge" size="smaller" /> {t('carrefour_badge')}
                </ColoredPill>
              )}
              {order.externalProvider === ExternalProvider.UberEats && (
                <ColoredPill color="UBER_EATS_GREEN">{t('uberEats_badge')}</ColoredPill>
              )}
              {order.externalProvider === ExternalProvider.Wolt && (
                <ColoredPill color="WOLT_BLUE">{t('wolt_badge')}</ColoredPill>
              )}
              {order.externalProvider === ExternalProvider.JustEat && (
                <ColoredPill color="JUST_EAT_ORANGE">{t('justEat_badge')}</ColoredPill>
              )}
              {order.externalProvider === ExternalProvider.JustEatRewe && (
                <div className="mt-1 mr-1 inline-flex overflow-hidden rounded-sm">
                  <Icon icon="jet-rewe-badge" className="h-6 w-[80px]" />
                </div>
              )}
              {order.isOutsourced &&
                order.outsourcingProviderName === OutsourcingProviderName.UberDirect && (
                  <ColoredPill color="BLACK">{t('uberDirect_badge')}</ColoredPill>
                )}
              {order.isOutsourced &&
                order.outsourcingProviderName === OutsourcingProviderName.WoltDrive && (
                  <ColoredPill color="WOLT_BLUE">{t('woltDrive_badge')}</ColoredPill>
                )}
              {order.shippingAddress?.tag === AddressTag.WORK && (
                <ColoredPill color="WORK_BADGE">
                  <Icon icon="work" size="smaller" />
                  {t('work')}
                </ColoredPill>
              )}
              {order.shippingAddress?.tag === AddressTag.OUTDOOR && (
                <ColoredPill color="VEGGIE_GREEN">
                  <Icon icon="park" size="smaller" />
                  {t('outdoor')}
                </ColoredPill>
              )}
            </div>
          </ColumnItem>
          <ColumnItem data-testid="order-basket-size">{order.basketSize}</ColumnItem>
          {!isFullScreen && <ColumnItem data-testid="order-price">{formattedPrice()}</ColumnItem>}
          <ColumnItem data-testid="order-delivery-time">{formattedPDT}</ColumnItem>
          <ColumnItem data-testid="order-picking-duration">
            {order?.pickingDuration ? `${order.pickingDuration} sec` : ''}
          </ColumnItem>
          <ColumnItem data-testid="order-rider-duration">{formattedRideTime}</ColumnItem>
          <ColumnItem>
            <OrderBadge order={order} />
          </ColumnItem>
          {!isFullScreen && (
            <ColumnItem>
              {actionsEnabled && (
                <div className="flex flex-col items-center">
                  <button
                    ref={actionsRef}
                    aria-label="trip-actions-button"
                    data-testid={`trip-actions-button-${order?.id}`}
                    className="py-2 px-4"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setActionsPopoverOpen(!actionsPopoverOpen);
                    }}
                  >
                    <Icon icon={'dots'} size={'small'} color="currentColor" />
                  </button>{' '}
                  <TripActions
                    refElement={actionsRef}
                    open={actionsPopoverOpen}
                    setOpen={setActionsPopoverOpen}
                    trip={trip}
                    availableActions={availableActions}
                    onAction={onAction}
                    setMapVisible={setMapVisible}
                  />
                </div>
              )}
            </ColumnItem>
          )}
        </OrderColumns>
      </div>
      {openPanel ? <Panel order={order} trip={trip} locale={locale} /> : null}
    </OrderWrapper>
  );
};

export default OrderComponent;
