import { lazy, Suspense, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from '@hub-orders-bff/schema/types';

import userContext from 'context/userContext';
import { useSortUniqueOrders } from 'hooks/useOrders';
import useOrders from 'hooks/useOrders';
import useRefresh from 'hooks/useRefresh';
import useShowScrollTop from 'hooks/useShowScrollTop';
import { searchTrips, getTrips, getCalculatedStats } from 'utils/trips';
import { on, Events, off } from 'utils/events';
import usePermissions, { Permission } from 'hooks/usePermissions';

import backToTopHandle from 'lib/backToTop';

import DynamicSelect, { RiderOption } from './DynamicSelect';
import CustomSelect, { Option } from 'components/CustomSelect';
import OrdersList from 'components/Order/OrdersList';
import OrdersHeader from 'components/Order/OrdersHeader';
import Filter from 'components/Filter';
import Stats from 'components/Stats/Stats';
import FullScreen from 'components/FullScreen/FullScreen';
import Icon from 'components/Icon/Icon';
import { ModalWrapper } from 'components/Map/ModalWrapper';
import { RidersModal } from 'components/RidersModal/RidersModal';
import { WarningBar } from 'components/WarningBar/WarningBar';

import './Dashboard.scss';
import { DashboardHeader, HubName, Main } from './style';
import { useSearchOrdersLazyQuery } from 'api/generated';
import { RiderWithStatus } from 'lib/constants';
import { orderFilters, STATE_FILTERS, ALL, providerFilters, filterByProvider } from './helpers';
import { Sound } from 'lib/sound';
import ping from 'assets/sounds/Ping.mp3';
import blow from 'assets/sounds/Blow.mp3';
import useStore from 'hooks/useStore';
import usePickersNeeded from 'hooks/usePickersNeeded';

const stackedSound = new Sound(ping);
const orderSound = new Sound(blow);

const RidersMap = lazy(() => import('components/RidersMap/RidersMap'));

const Dashboard = () => {
  useRefresh();
  const { t } = useTranslation();
  const tripActionMessagesRef = useRef(null);
  const { hasPermission } = usePermissions([Permission.WRITE_ORDERS_ALL]);
  const { hasPermission: hasRiderProfilePermission } = usePermissions([
    Permission.WRITE_RIDERPROFILE_ALL,
  ]);
  const { isFullScreen, query } = useStore((state) => ({
    isFullScreen: state.isFullScreen,
    query: state.searchQuery,
  }));
  const hasQuery = query.length > 1;
  const [headerRef, scrollToTopIsVisible] = useShowScrollTop();
  const filterOptions = orderFilters.map(({ value, label }) => ({
    value,
    label: t(label),
  }));
  const providerFilterOptions = providerFilters.map(({ value, label }) => ({
    value,
    label: t(label),
  }));
  const [riderFilter, setRiderFilter] = useState<RiderOption | null>(null);
  const [orderFilter, setOrderFilter] = useState<Option>(filterOptions[0]);
  const [providerFilter, setProviderFilter] = useState<Option>(providerFilterOptions[0]);
  const [ridersMapVisible, setRidersMapVisible] = useState(false);
  const [underageRiders, setUnderageRiders] = useState<RiderWithStatus[]>([]);
  const [tempOfflineRiders, setTempOfflineRiders] = useState<RiderWithStatus[]>([]);

  const [ridersModal, setRidersModal] = useState<'underage' | 'temp_offline' | null>(null);

  const { selectedHub } = useContext(userContext);
  const {
    bottleneck,
    hasNewOrder,
    hasNewStackedOrder,
    fetchedOrder,
    trips: fetchedTrips,
    loading,
    isPollingStopped,
    error,
  } = useOrders({
    slug: selectedHub?.slug ?? '',
  });

  const [search, { data, loading: searchLoading }] = useSearchOrdersLazyQuery();

  const { pickersNeeded } = usePickersNeeded({
    slug: selectedHub?.slug ?? '',
  });

  useEffect(() => {
    const riderLocationsListener = () => setRidersMapVisible(true);
    const underageRidersListener = ({ detail }: any) => setUnderageRiders(detail);
    const tempOfflineRidersListener = ({ detail }: any) => setTempOfflineRiders(detail);

    on(Events.VIEW_RIDER_LOCATIONS, riderLocationsListener);
    on(Events.UNDERAGE_RIDERS, underageRidersListener);
    on(Events.TEMP_OFFLINE_RIDERS, tempOfflineRidersListener);

    return () => {
      off(Events.VIEW_RIDER_LOCATIONS, riderLocationsListener);
      off(Events.UNDERAGE_RIDERS, underageRidersListener);
      off(Events.TEMP_OFFLINE_RIDERS, tempOfflineRidersListener);
    };
  }, []);

  useEffect(() => {
    // prioritize new stacked order sound over new order sound
    if (hasNewStackedOrder) {
      stackedSound.play();
    } else if (hasNewOrder) {
      orderSound.play();
    }
    return () => {
      stackedSound.pause();
      orderSound.pause();
    };
  }, [hasNewOrder, hasNewStackedOrder]);

  const orders = useSortUniqueOrders(fetchedOrder, loading);
  const trips = useMemo(() => getTrips(fetchedTrips, orders), [fetchedTrips, orders]);

  const orderNumbersWithRiderIds = useMemo(
    () =>
      orders.map((order) => ({
        orderNumber: order.orderNumber as string,
        riderId: order.riderId as string,
        state: order.state as number,
      })),
    [orders]
  );

  const searchedTrips = useMemo(
    () => searchTrips(trips, (data?.searchOrders || []) as Order[]),
    [data, trips]
  );

  const onChangeRiderFilter = (nextRider: RiderOption | null) => {
    setRiderFilter(nextRider);
    if (nextRider) {
      search({
        variables: {
          slug: selectedHub?.slug as string,
          query: nextRider.id,
          includeRider: true,
        },
      });
    }
  };

  useEffect(() => {
    if (selectedHub?.slug && hasQuery) {
      search({
        variables: {
          slug: selectedHub.slug,
          query,
          includeRider: true,
        },
      });
    }
  }, [hasQuery, query, search, selectedHub]);

  const tripList = useMemo(() => {
    if (hasQuery || riderFilter) {
      return searchedTrips;
    } else if (STATE_FILTERS.includes(orderFilter.value as number)) {
      return trips
        .filter((trip) => trip.states.includes(orderFilter.value as number))
        .filter(filterByProvider(providerFilter.value as string));
    } else {
      return trips.filter(filterByProvider(providerFilter.value as string));
    }
  }, [hasQuery, orderFilter.value, providerFilter.value, riderFilter, searchedTrips, trips]);

  const searchResultCount = useMemo(() => {
    return searchedTrips.reduce((acc, trip): number => acc + trip.orders.length, 0);
  }, [searchedTrips]);

  const calculatedStats = useMemo(() => {
    return getCalculatedStats(trips);
  }, [trips]);

  return (
    <Main>
      {scrollToTopIsVisible && (
        <button className="button__scroll-top-btn" onClick={backToTopHandle}>
          <Icon icon="chevron-up" size="large" color="black" />
        </button>
      )}
      <div className="sticky top-0 z-10 bg-flinkGray-medium pt-1 text-white">
        <FullScreen />
        <DashboardHeader active={scrollToTopIsVisible}>
          <HubName active={scrollToTopIsVisible} data-testid="hub-name">
            {selectedHub?.name}
          </HubName>
          <Stats
            active={scrollToTopIsVisible}
            queuedOrderCount={calculatedStats.queuedOrderCount}
            readyToPickCount={calculatedStats.readyToPickCount}
            pickingCount={calculatedStats.pickingCount}
            readyToDeliverCount={calculatedStats.readyToDeliverCount}
            externalReadyToDeliverCount={calculatedStats.externalReadyToDeliverCount}
            pickersNeededCount={pickersNeeded || 0}
            bottleneck={bottleneck}
          />
          {underageRiders.length > 0 ? (
            <WarningBar
              labels={{ title: `⏰ ${t('riders_underage_warning')}`, details: t('view_list') }}
              onClickDetails={() => setRidersModal('underage')}
            />
          ) : tempOfflineRiders.length > 0 ? (
            <WarningBar
              labels={{
                title: `⚠️ ${t('riders_temp_offline_warning')}`,
                details: hasRiderProfilePermission ? t('view_list') : '',
              }}
              onClickDetails={() => setRidersModal('temp_offline')}
            />
          ) : null}
          {!isFullScreen && (
            <div className="mt-4 mb-2 flex flex-wrap items-start justify-between gap-2">
              <div className="flex gap-2">
                <CustomSelect
                  options={filterOptions}
                  displayValue={orderFilter.label}
                  onSelect={setOrderFilter}
                  selectedOption={orderFilter}
                />
                <CustomSelect
                  options={providerFilterOptions}
                  displayValue={providerFilter.label}
                  onSelect={setProviderFilter}
                  selectedOption={providerFilter}
                />
                <DynamicSelect
                  onSelect={onChangeRiderFilter}
                  selectedOption={riderFilter}
                  slug={selectedHub?.slug as string}
                />
              </div>
              <div>
                <Filter resultCount={searchResultCount} />
              </div>
            </div>
          )}
          <div className="mb-4" ref={tripActionMessagesRef} />
          <OrdersHeader />
        </DashboardHeader>
      </div>
      <div className="orders" ref={headerRef}>
        {searchLoading && <div className="orders__notavail">{t('warehouse_loading')}</div>}
        {!isPollingStopped && !orders.length && loading && !error && (
          <div className="orders__notavail">{t('warehouse_loading')}</div>
        )}
        {!isPollingStopped && !orders.length && !loading && !error && (
          <div className="orders__notavail">{t('no_orders_available')}</div>
        )}
        {!isPollingStopped && error ? (
          <div className="orders__notavail">{error.message}</div>
        ) : null}
        {isPollingStopped && (
          <div className="orders__notavail">
            <p>{t('order_errorfetching')}</p>
            <p className="mt-2 text-sm">{error?.message}</p>
          </div>
        )}
        {!!orders.length && (
          <OrdersList
            tripActionMessagesRef={tripActionMessagesRef}
            showAllOrders={hasQuery || orderFilter.value === ALL}
            trips={tripList}
            slug={selectedHub?.slug ?? ''}
            isAllowedToUpdate={hasPermission}
          />
        )}
      </div>
      <ModalWrapper isOpen={ridersMapVisible} onClose={() => setRidersMapVisible(false)}>
        <div className="relative z-0">
          <button
            className="color-flinkGray-light absolute top-2 right-2 z-20 flex rounded-lg bg-flinkGray-medium py-2 px-4 text-sm"
            onClick={() => setRidersMapVisible(false)}
          >
            <Icon icon={'eye-hide'} size={'small'} color="currentColor" className="mr-2" />
            {t('riders_hide_map')}
          </button>
          <Suspense fallback={<div>...</div>}>
            <RidersMap orders={orderNumbersWithRiderIds} />
          </Suspense>
        </div>
      </ModalWrapper>
      <RidersModal
        title={`⏰ ${t('riders_underage_modal_title')}`}
        isOpen={ridersModal === 'underage'}
        onCancel={() => setRidersModal(null)}
        onConfirm={() => setRidersModal(null)}
        riders={underageRiders}
      />
      <RidersModal
        title={`⚠️ ${t('riders_temp_offline_modal_title')}`}
        isOpen={ridersModal === 'temp_offline'}
        onCancel={() => setRidersModal(null)}
        onConfirm={() => setRidersModal(null)}
        riders={tempOfflineRiders}
      />
    </Main>
  );
};

export default Dashboard;
