import en from './locales/en.json';
import de from './locales/de.json';
import nl from './locales/nl.json';
import fr from './locales/fr.json';

import { LocalesEnum } from 'lib/constants';

export const config = {
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.ENV === 'development',
  resources: {
    [LocalesEnum.EN]: { translation: en },
    [LocalesEnum.DE]: { translation: de },
    [LocalesEnum.NL]: { translation: nl },
    [LocalesEnum.FR]: { translation: fr },
  },
};
