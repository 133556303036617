import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';

import client from './client/module';
import Dashboard from 'pages/Dashboard/Dashboard';
import { colors, sizes, typography, mixins } from 'theme/index';

export const App = () => (
  <ApolloProvider client={client()}>
    <ThemeProvider theme={{ colors, sizes, typography, mixins }}>
      <div id="orders-module" className="w-full">
        <div className="min-h-screen bg-flinkGray-medium">
          <Dashboard />
        </div>
      </div>
    </ThemeProvider>
  </ApolloProvider>
);
