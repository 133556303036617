import { useEffect, useState } from 'react';
import sdk from '@hubportal/sdk';
import jwtDecode, { JwtPayload } from 'jwt-decode';

type PermissionsResult = {
  hasPermission: boolean;
};

export enum Permission {
  WRITE_ORDERS_ALL = 'write:orders:all',
  READ_ORDERS_ALL = 'read:orders:all',
  WRITE_RIDERPROFILE_ALL = 'write:riderprofile:all',
}

const usePermissions = (permissions: Permission[]): PermissionsResult => {
  const [isReady, setReady] = useState<boolean>(false);
  const [hasPermission, setHasPermission] = useState<boolean>(false);

  useEffect(() => {
    const parseUserPermissions = async (): Promise<void> => {
      try {
        const token = await sdk.getAuthClient().getTokenSilently({
          audience: process.env.BFF_AUDIENCE,
        });
        const decodedToken = jwtDecode<JwtPayload & { permissions?: Permission[] }>(token) || {};
        const { permissions: jwtPermissions = [] } = decodedToken;

        setHasPermission(jwtPermissions.some((permission) => permissions.includes(permission)));
      } catch (err) {
        sdk.reportError(err, '@hubportal/orders', {
          message: 'usePermissions: parse user permissions error',
        });
      } finally {
        setReady(true);
      }
    };

    if (!isReady) {
      parseUserPermissions();
    }
  }, [isReady, permissions]);

  return {
    hasPermission,
  };
};

export default usePermissions;
