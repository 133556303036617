import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import singleSpaCss from 'single-spa-css';
import Root from './root.component';
import sdk from '@hubportal/sdk';

const cssLifecycles = singleSpaCss({
  cssUrls: [],
  webpackExtractedCss: process.env.ENV !== 'development',
  shouldUnmount: true,
});
const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  renderType: 'createRoot',
  errorBoundary(err, info) {
    sdk.reportError(err, '@hubportal/orders', info);

    return (
      <div>
        <h1>Something went wrong.</h1>
      </div>
    );
  },
});

export const bootstrap = [cssLifecycles.bootstrap, reactLifecycles.bootstrap];

export const mount = [cssLifecycles.mount, reactLifecycles.mount];

export const unmount = [reactLifecycles.unmount, cssLifecycles.unmount];
