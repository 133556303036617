import { useEffect, useState } from 'react';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import isAfter from 'date-fns/isAfter';
import { StateCodesEnum } from 'lib/constants';
import convertSecondsToTime from 'lib/convertSecondsToTime';

const formatElapsedTime = (seconds: number) => {
  return `${convertSecondsToTime(seconds)} min`;
};

const getElapsedTime = (created: string): string => {
  const seconds = differenceInSeconds(new Date(), new Date(created));
  return formatElapsedTime(seconds);
};

type Props = {
  state: number;
  createdAt: string;
  deliveryDuration: number;
  isPlanned?: boolean;
  activeTimestamp?: string;
  deliveredAt?: string;
};

const PLACEHOLDER = '00:00 min';
const INTERVAL = 1000;
const cancelledElapsedTime = '--:--';

const ElapsedTime = ({
  state,
  createdAt,
  deliveryDuration,
  isPlanned,
  activeTimestamp = '',
  deliveredAt = '',
}: Props) => {
  const [elapsed, setElapsed] = useState<string>('');
  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (state === StateCodesEnum.DELIVERED) {
      if (isPlanned && activeTimestamp) {
        const diff = Date.parse(deliveredAt) - Date.parse(activeTimestamp);
        setElapsed(formatElapsedTime(diff / 1000));
      } else {
        setElapsed(formatElapsedTime(deliveryDuration));
      }
    } else if (state === StateCodesEnum.CANCELLED) {
      setElapsed(cancelledElapsedTime);
    } else {
      intervalId = setInterval(() => {
        if (isPlanned) {
          const isInactive = isAfter(Date.parse(activeTimestamp || ''), new Date().getTime());
          if (isInactive) {
            setElapsed(cancelledElapsedTime);
          } else {
            setElapsed(getElapsedTime(activeTimestamp));
          }
        } else {
          setElapsed(getElapsedTime(createdAt));
        }
      }, INTERVAL);
    }

    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [createdAt, deliveryDuration, state, isPlanned, activeTimestamp, deliveredAt]);

  return (
    <div data-testid="order-elapsed-time" className="text-base font-semibold">
      <span className={elapsed ? '' : 'blur-sm'}>{elapsed || PLACEHOLDER}</span>
    </div>
  );
};

export default ElapsedTime;
