import { memo, Suspense, CSSProperties, lazy, createElement } from 'react';
import { WHITE, GRAY_MEDIUM } from 'theme/colors';
import './Icon.scss';

type Props = {
  icon: string;
  color?: string;
  backgroundColor?: string;
  size?: string;
  withBackground?: boolean;
  hoverColor?: boolean;
  className?: string;
};

const iconSize = (size: string, color: string, backgroundColor: string): CSSProperties => {
  const setDimension = (dimension: string): string => {
    switch (dimension) {
      case 'smaller':
        return '14px';
      case 'small':
        return '20px';
      case 'large':
        return '35px';
      default:
        return '28px';
    }
  };

  return {
    ['--size' as string]: setDimension(size),
    ['--color' as string]: color,
    ['--backgroundColor' as string]: backgroundColor,
  };
};

const Component = (icon: string) => {
  return lazy(() => import(`/src/assets/icons/${icon}.tsx`));
};

const Icon = ({
  icon,
  color = WHITE,
  backgroundColor = GRAY_MEDIUM,
  size = 'medium',
  withBackground,
  className = '',
}: Props) => {
  return (
    <div
      style={iconSize(size, color, backgroundColor)}
      className={`icon ${withBackground ? 'icon--background' : ''} icon--${size} ${className}`}
      data-testid={`icon-${icon}`}
    >
      <Suspense fallback={<div />}>{createElement(Component(icon))}</Suspense>
    </div>
  );
};

export default memo(Icon);
