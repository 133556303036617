export class Sound {
  readonly sound: HTMLAudioElement;

  constructor(source: string) {
    this.sound = new Audio(source);
  }

  async play() {
    this.sound.currentTime = 0;
    // some browsers don't allow play sound without first user interaction
    return this.sound.play().catch(() => {});
  }

  async pause() {
    this.sound.currentTime = 0;
    this.sound.pause();
  }
}
