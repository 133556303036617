import { useEffect, useMemo, useRef, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';

import { Order, Trip, useGetOrdersWithTripsAndRidersQuery } from 'api/generated';

type UseOrdersVariables = {
  slug: string;
  interval?: number;
};

const ERROR_ALLOWANCE_LIMIT = 4;
const TIME_OFFSET = 30;
const FETCH_INTERVAL = 5000;

const compareOrders = (a: Order | null, b: Order | null): number => {
  const { createdAt: d1 } = a || { createdAt: '' };
  const { createdAt: d2 } = b || { createdAt: '' };

  if (d2 && d1) {
    return +new Date(d2).valueOf() - new Date(d1).valueOf();
  }

  return 0;
};

export const useSortUniqueOrders = (orders: (Order | null)[], skip: boolean): Order[] => {
  const [sortedOrders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    if (!skip && orders.length) {
      setOrders((oldSortedOrders) => {
        const uniqOrders = uniqBy([...orders, ...(oldSortedOrders || [])], 'id') as Order[];
        return uniqOrders.sort(compareOrders);
      });
    }
  }, [orders, skip]);

  return sortedOrders;
};

const useOrders = ({ slug, interval }: UseOrdersVariables) => {
  const firstRender = useRef<boolean>(true);
  const hasNewOrder = useRef<boolean>(false);
  const hasNewStackedOrder = useRef<boolean>(false);
  const [isPollingStopped, setIsPollingStopped] = useState<boolean>(false);
  const [errorCount, setErrorCount] = useState(0);

  const { data, previousData, refetch, networkStatus, loading, stopPolling, error } =
    useGetOrdersWithTripsAndRidersQuery({
      variables: {
        slug,
        lastModifiedTimeSpan: 0,
      },
      notifyOnNetworkStatusChange: true,
      pollInterval: interval ?? FETCH_INTERVAL,
      onCompleted: () => {
        if (errorCount) {
          setErrorCount(0);
        }
      },
      onError: ({ networkError, graphQLErrors }) => {
        if (errorCount >= ERROR_ALLOWANCE_LIMIT && (networkError || graphQLErrors)) {
          stopPolling();
          setIsPollingStopped(true);
        }
        setErrorCount((prevCount) => prevCount + 1);
      },
    });

  const orderCount = useMemo(() => {
    const current =
      data?.tripsV2?.trips?.filter((trip) => !trip?.heldBackFromPicking) || ([] as Trip[]);
    const previous =
      previousData?.tripsV2?.trips?.filter((trip) => !trip?.heldBackFromPicking) || [];
    const currentStacked = current.reduce(
      (acc, trip): number => ((trip?.orderIds as string[])?.length > 1 ? acc + 1 : acc),
      0
    );
    const previousStacked = previous.reduce(
      (acc, trip): number => ((trip?.orderIds as string[])?.length > 1 ? acc + 1 : acc),
      0
    );

    return { previous: previous.length, current: current.length, currentStacked, previousStacked };
  }, [data, previousData]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready && firstRender.current) {
      refetch({ lastModifiedTimeSpan: TIME_OFFSET });
      firstRender.current = false;
    }
  }, [refetch, networkStatus]);

  useEffect(() => {
    if (orderCount.previous || orderCount.previousStacked) {
      hasNewOrder.current = orderCount.current > orderCount.previous;
      hasNewStackedOrder.current = orderCount.currentStacked > orderCount.previousStacked;
    }
  }, [orderCount]);

  const normalizedTrips = useMemo(() => (data?.tripsV2?.trips || []) as Trip[], [data]);
  const normalizedOrders = useMemo(() => (data?.orders || []) as Order[], [data]);

  return {
    bottleneck: data?.tripsV2?.bottleneck || '',
    hasNewOrder: hasNewOrder.current,
    hasNewStackedOrder: hasNewStackedOrder.current,
    fetchedOrder: normalizedOrders,
    trips: normalizedTrips,
    loading: firstRender.current ? loading : false,
    isPollingStopped,
    error,
  };
};

export default useOrders;
