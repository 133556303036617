import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Order, ShippingMethod } from '@hub-orders-bff/schema/types';

import Icon from 'components/Icon/Icon';
import QRCode from 'components/QRCode/QRCode';
import useOnClickOutside from 'hooks/useOnClickOutside';

import { StateCodesEnum, statusLabels } from 'lib/constants';

import './Panel.scss';
import { Button, Popover, Block, CloseButton } from './style';
import Badge from 'components/Badge';
import { formatPromisedTime } from 'lib/orderHelpers';
import { LocaleT } from '@hubportal/sdk';

interface Props {
  order: Order;
  trip: Order[];
  locale: LocaleT;
}

const Panel = ({ order, trip, locale }: Props) => {
  const { t } = useTranslation();
  const [showQR, setShowQR] = useState<boolean>(false);
  const popoverRef = useRef(null);
  const openQrRef = useRef(null);

  useOnClickOutside(popoverRef, openQrRef, () => {
    if (showQR) {
      setShowQR(false);
    }
  });

  const displayQRCode =
    order.shippingMethod !== ShippingMethod.ClickAndCollect &&
    (order.state === StateCodesEnum.CREATED || order.state === StateCodesEnum.PICKERACCEPTED);
  const sortedLines = useCallback(() => {
    if (order?.lines) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const lines = [...order?.lines];
      lines.sort((a, b) => {
        if (a?.shelfNo && b?.shelfNo) {
          if (a.shelfNo < b.shelfNo) {
            return -1;
          }
          if (a.shelfNo > b.shelfNo) {
            return 1;
          }
        }

        return 0;
      });
      return lines;
    } else {
      return [];
    }
  }, [order]);

  const containerColor = (containerId: string) => {
    // This covers the transition phase with having different containerId formats with size digits at different indexes

    // Old Container format: ContainerID: 1D43AF1G - Hub Order bff -> 43AF1G - The 3 determine the containe color and size
    // Option 1 container format: ContainerID; DBR2sheet - Hub Order bff -> 2sheet - The 2 determines the container color and size

    // Since we return the last 6 characters of the containerId (43AF1G or 2sheet) from the Hub-order-bff, we need to check the 2nd character to know which format it is in.
    // Old formats 2nd character would be a number while new formats would be a letter.

    // Checking for the 5 last character starting with a number
    if (containerId.slice(-5).match(/^\d/)) {
      containerId = containerId.slice(-5);
    }
    if (containerId.startsWith('1')) {
      return 'bg-green-400';
    } else if (containerId.startsWith('2')) {
      return 'bg-purple-400';
    } else {
      return 'bg-orange-400';
    }
  };

  return (
    <div className="rounded-md bg-flinkGray-dark px-6 pt-8 pb-4">
      <div className="panel">
        <div className="panel__column">
          {order?.shelfNumbers && (
            <>
              <div className="panel__heading">
                <Icon icon="shelf" color="currentColor" />
                <h4 className="panel__h4">{t('shelf_numbers')}</h4>
              </div>
              <div className="panel__list">
                <div className="flex flex-wrap gap-2">
                  {order.shelfNumbers.map((shelfNumber) => (
                    <Badge key={shelfNumber} label={shelfNumber} />
                  ))}
                </div>
              </div>
            </>
          )}
          <div className="panel__heading">
            <Icon icon="scan" color="currentColor" />
            <h4 className="panel__h4">{t('order_billingaddress')}</h4>
          </div>
          <ul className="panel__list">
            {order?.shippingAddress?.title ? (
              <li>
                <span>{t('order_title')}</span>
                <span>{order?.shippingAddress?.title}</span>
              </li>
            ) : null}
            {order?.shippingAddress?.firstName ? (
              <li>
                <span>{t('order_customername')}</span>
                <span>
                  {order?.shippingAddress?.firstName} {order?.shippingAddress?.lastName}
                </span>
              </li>
            ) : null}
            {order.customer?.email ? (
              <li>
                <span>{t('order_customeremail')}</span>
                <span className="break-all">{order.customer?.email}</span>
              </li>
            ) : null}
            {order.shippingAddress?.phone ? (
              <li>
                <span>{t('order_phone')}</span>
                <span>{order.shippingAddress?.phone}</span>
              </li>
            ) : null}
            {order.shippingAddress?.mobile ? (
              <li>
                <span>{t('order_mobile')}</span>
                <span>{order.shippingAddress?.mobile}</span>
              </li>
            ) : null}
          </ul>
          {!(order.shippingMethod === ShippingMethod.ClickAndCollect) && (
            <>
              <div className="panel__heading">
                <Icon icon="address" color="currentColor" />
                <h4 className="panel__h4">{t('order_shippingaddress')}</h4>
              </div>
              <ul className="panel__list">
                {order.shippingAddress?.streetName ? (
                  <li>
                    <span>{t('order_streetaddress')}</span>
                    <span>
                      {order.shippingAddress?.streetName}
                      {order.shippingAddress?.streetNumber}
                    </span>
                  </li>
                ) : null}
                {order.shippingAddress?.postalCode ? (
                  <li>
                    <span>{t('order_postalcode')}</span>
                    <span>{order.shippingAddress?.postalCode}</span>
                  </li>
                ) : null}
                {order.shippingAddress?.city ? (
                  <li>
                    <span>{t('order_city')}</span>
                    <span>{order.shippingAddress?.city}</span>
                  </li>
                ) : null}
                {order.shippingAddress?.buildingType ? (
                  <li>
                    <span>{t('order_buildingType')}</span>
                    <span>{order.shippingAddress?.buildingType}</span>
                  </li>
                ) : null}
                {order.shippingAddress?.nameOnDoor ? (
                  <li>
                    <span>{t('order_nameOnDoor')}</span>
                    <span>{order.shippingAddress?.nameOnDoor}</span>
                  </li>
                ) : null}
                {order.shippingAddress?.entrance ? (
                  <li>
                    <span>{t('order_entrance')}</span>
                    <span>{order.shippingAddress?.entrance}</span>
                  </li>
                ) : null}
                {order.shippingAddress?.floorNumber ? (
                  <li>
                    <span>{t('order_floorNumber')}</span>
                    <span>{order.shippingAddress?.floorNumber}</span>
                  </li>
                ) : null}
                {order.shippingAddress?.deliveryNote ? (
                  <li>
                    <span>{t('order_delivery_note')}:</span>
                    <span>{order.shippingAddress?.deliveryNote}</span>
                  </li>
                ) : null}
              </ul>
            </>
          )}
          {order.shippingMethod === ShippingMethod.ClickAndCollect && (
            <>
              <div className="panel__heading">
                <Icon icon="billing" color="currentColor" />
                <h4 className="panel__h4">{t('click_and_collect')}</h4>
              </div>
              <QRCode
                codeWrapperClass="mx-8 mt-2 w-40"
                textWrapperClass="items-center py-2 px-6 w-96"
                order={order}
                size={120}
              />
            </>
          )}

          {order.rider && (
            <>
              <div className="panel__heading">
                <Icon icon="bike" color="currentColor" />
                <h4 className="panel__h4">{t('order_rider')}</h4>
              </div>
              <div
                className="panel__rider"
                onClick={() => window.open(`/workforce/${order.riderId}`, '_blank')}
              >
                <div>{`${order.rider?.lastName}, ${order.rider?.firstName}`}</div>
                <div>{order.rider?.phoneNumber}</div>
                <Icon className="panel__rider__icon" icon="arrow-right" />
              </div>
            </>
          )}

          {trip.length >= 2 && (
            <>
              <div className="panel__heading">
                <Icon icon="stack" color="currentColor" />
                <h4 className="panel__h4">{t('order_trip')}</h4>
              </div>

              <table className="panel__table">
                <thead>
                  <tr>
                    <th className="px-2 text-center font-normal">#</th>
                    <th className="px-2 font-normal">{t('order_column_id')}</th>
                    <th className="px-2 font-normal">{t('order_column_countdown')}</th>
                    <th className="px-2 font-normal">{t('order_column_promised_time')}</th>
                  </tr>
                </thead>
                <tbody>
                  {trip.map((stackedOrder, idx) => (
                    <tr key={stackedOrder?.id} className="text-left text-base text-flinkGray-light">
                      <td className="px-2 text-center">
                        <span
                          className={`inline-block h-6 w-6 rounded-full text-white ${
                            idx === trip.findIndex((o) => o.id === stackedOrder?.id)
                              ? 'bg-flinkPink'
                              : 'bg-flinkGray-medium'
                          }`}
                        >
                          {idx + 1}
                        </span>
                      </td>
                      <td className="px-2">{stackedOrder.orderNumber}</td>
                      <td className="px-2">
                        {t(
                          statusLabels.find((s) => s.number === stackedOrder?.state)?.description ||
                            ''
                        )}
                      </td>
                      <td className="px-2">{formatPromisedTime(stackedOrder, locale)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>

        <div className="panel__column">
          {order?.containerIds && (
            <>
              <div className="panel__heading">
                <Icon icon="container" color="currentColor" />
                <h4 className="panel__h4">{t('container_ids')}</h4>
              </div>
              <div className="panel__list">
                <div className="flex flex-wrap gap-2">
                  {order.containerIds.map((containerId) => (
                    <Badge
                      key={containerId}
                      label={containerId.slice(-5)}
                      bgColor={containerColor(containerId)}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
          {order.lines?.length && (
            <>
              <div className="panel__heading">
                <Icon icon="basket-size" color="currentColor" />
                <h4 className="panel__h4">{t('order_basketitems')}</h4>
              </div>
              <table className="panel__table">
                <thead>
                  <tr className="panel__tr">
                    <th>{t('order_product')}</th>
                    <th>{t('order_product_shelf')}</th>
                    <th>{t('order_product_quantity')}</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedLines().map((line, idx) => (
                    <tr className="panel__item" key={`${line?.name}${idx}`}>
                      <td>{line?.name}</td>
                      <td>{line?.shelfNo}</td>
                      <td>{line?.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      {order?.externalOrderNumber || displayQRCode ? (
        <div className="panel panel_footer">
          <div className="panel__column">
            {order?.externalOrderNumber ? (
              <ul className="panel__list">
                <li>
                  <span>{t('order_external_order_number')}</span>
                  <span>{order.externalOrderNumber}</span>
                </li>
              </ul>
            ) : null}
          </div>
          <div className={'panel__column'}>
            {displayQRCode ? (
              <Block>
                <Popover ref={popoverRef} hidden={!showQR} data-testid="popover">
                  <CloseButton onClick={() => setShowQR(false)}>
                    <Icon icon={'close'} withBackground={true} />
                  </CloseButton>
                  <QRCode order={order} />
                </Popover>
                <Button ref={openQrRef} onClick={() => setShowQR(!showQR)}>
                  {t('order_qr_code_button_label')}
                </Button>
              </Block>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Panel;
