import Modal from 'components/Modal';
import { RiderWithStatus } from 'lib/constants';
import { getRiderStateColor, getRiderStateLabel } from 'pages/Dashboard/helpers';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  riders: RiderWithStatus[];
  title: string;
};

export const RidersModal = ({ isOpen, onCancel, onConfirm, riders, title }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      submitText={t('underage_confirm')}
      onConfirm={onConfirm}
    >
      <div className="z-50 px-6 pb-6">
        <div className="mb-3 text-left">
          <h2 className="mb-6 text-center font-flink text-lg">{title}</h2>
          <div className="relative rounded border border-flinkGray-dark border-opacity-80 bg-flinkGray-dark bg-opacity-40 p-6">
            <div className="mt-1 h-72 overflow-auto">
              <ul className="w-full">
                {riders.map((rider) => {
                  return (
                    <li key={rider.id} className="flex items-center justify-between p-4">
                      <div>{`${rider.firstName} ${rider.lastName}`}</div>
                      <div className="flex items-center text-sm text-flinkGray-light">
                        <div
                          className={`mr-2 h-2 w-2 rounded ${getRiderStateColor(
                            rider.status,
                            rider.statusReason
                          )}`}
                        />
                        {t(getRiderStateLabel(rider.status, rider.statusReason))}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
