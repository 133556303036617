import { useTranslation } from 'react-i18next';
import Code from 'react-qr-code';
import { Order } from '@hub-orders-bff/schema/types';

import Phone from 'assets/images/Phone';
import { Content, CodeWrapper } from './style';

const QRCode = ({
  order,
  size,
  codeWrapperClass,
  textWrapperClass,
}: {
  order: Order;
  size?: number;
  codeWrapperClass?: string;
  textWrapperClass?: string;
}) => {
  const replacer = (key: string, value: string) => {
    // any non word character excluding space
    const regex = /[^\w ]/g;
    if (['first_name', 'last_name'].includes(key)) {
      return value.replace(regex, '');
    }
    return value;
  };

  const { id, orderNumber, customer, shippingAddress } = order;
  const { t } = useTranslation();

  const qrCodeValue = JSON.stringify(
    {
      picking_new_order: {
        id,
        number: orderNumber,
        is_new_customer: customer?.isNewCustomer,
        customer_email: customer?.email,
        first_name: shippingAddress?.firstName,
        last_name: shippingAddress?.lastName,
      },
    },
    replacer
  );

  return (
    <>
      <Content className={textWrapperClass}>
        <Phone />
        <p>{t('order_qr_code_header')}</p>
      </Content>

      <CodeWrapper className={codeWrapperClass}>
        <Code value={qrCodeValue} size={size || 210} level={'L'} />
      </CodeWrapper>
    </>
  );
};

export default QRCode;
