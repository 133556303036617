import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { Popover } from 'components/Popover/Popover';
import { TripActionModalType } from 'lib/constants';
import { Order } from 'api/generated';
import Icon from 'components/Icon/Icon';

type TripActionsProps = {
  refElement: MutableRefObject<null>;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  trip: Order[];
  setMapVisible: (visible: boolean) => void;
  availableActions?: {
    isTripCompletable?: boolean;
    isTripUnassignable?: boolean;
    isUnstackable?: boolean;
    isTripAssignable?: boolean;
  };
  onAction: (actionType: TripActionModalType, data: Order[]) => void;
};

const TripAction = ({
  testId,
  icon,
  label,
  onClick,
}: {
  testId: string;
  icon: JSX.Element;
  label: string;
  onClick: () => void;
}) => {
  return (
    <button className="my-4 flex whitespace-nowrap text-sm" data-testid={testId} onClick={onClick}>
      <div className="mr-2">{icon}</div>
      {label}
    </button>
  );
};

const TripActions = ({
  refElement,
  open,
  setOpen,
  trip,
  setMapVisible,
  availableActions,
  onAction,
}: TripActionsProps) => {
  const { t } = useTranslation();
  const { isUnstackable, isTripUnassignable, isTripCompletable, isTripAssignable } =
    availableActions || {};

  const unstack = () => {
    onAction(TripActionModalType.UNSTACK, trip);
    setOpen(false);
  };

  const complete = () => {
    onAction(TripActionModalType.COMPLETE_TRIP, trip);
    setOpen(false);
  };

  const unassign = () => {
    onAction(TripActionModalType.UNASSIGN_RIDER, trip);
    setOpen(false);
  };

  const assign = () => {
    onAction(TripActionModalType.ASSIGN_RIDER, trip);
    setOpen(false);
  };

  return (
    <Popover refElement={refElement} open={open} onClose={() => setOpen(false)}>
      {isUnstackable && (
        <TripAction
          testId="unstack-button"
          icon={<Icon icon={'unstack'} size={'small'} color="currentColor" />}
          label={t('unstack_trip')}
          onClick={unstack}
        />
      )}
      {isTripCompletable && (
        <TripAction
          testId="complete-trip-button"
          icon={<Icon icon={'flink-bag'} size={'small'} color="currentColor" />}
          label={t('complete_trip')}
          onClick={complete}
        />
      )}
      {isTripUnassignable && (
        <TripAction
          testId="unassign-rider-button"
          icon={<Icon icon={'bike'} size={'small'} color="currentColor" />}
          label={t('order_unassign_rider')}
          onClick={unassign}
        />
      )}
      {isTripAssignable && (
        <TripAction
          testId="assign-rider-button"
          icon={<Icon icon={'bike'} size={'small'} color="currentColor" />}
          label={t('assign_rider')}
          onClick={assign}
        />
      )}
      <TripAction
        testId="show-map-button"
        icon={<Icon icon={'map'} size={'small'} color="currentColor" />}
        label={t('order_show_map')}
        onClick={() => {
          setMapVisible(true);
          setOpen(false);
        }}
      />
    </Popover>
  );
};

export default TripActions;
