import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from '@hub-orders-bff/schema/types';

import { StateCodesEnum, statusLabels } from 'lib/constants';
import { isDeliveredLate, isPlannedOrder } from 'lib/orderHelpers';
import Icon from 'components/Icon/Icon';
import ElapsedTime from './ElapsedTime';

type Props = {
  order: Order;
};

const activeStates = [StateCodesEnum.PICKERACCEPTED, StateCodesEnum.ONROUTE];

const OrderBadge = ({ order }: Props) => {
  const { t } = useTranslation();
  const isActiveState = activeStates.includes(order.state as number);

  const orderStatus = useMemo(() => {
    const statusCode = statusLabels.find((s) => s.number === order?.state);
    return t(statusCode?.description || '');
  }, [order, t]);

  const isPlanned = useMemo(() => {
    return isPlannedOrder(order);
  }, [order?.plannedOrderInformation]);

  const isLate = useMemo(() => {
    return isDeliveredLate(order);
  }, [order]);

  const colorClass = useMemo(() => {
    if (isActiveState) {
      return 'text-white';
    } else if (order.state === StateCodesEnum.DELIVERED && isLate) {
      return 'text-white bg-red-600';
    } else if (order.state === StateCodesEnum.PACKED) {
      return 'text-white bg-blue-dark';
    } else {
      return 'text-flinkGray-light';
    }
  }, [isActiveState, isLate, order.state]);

  return (
    <div className={`${colorClass} rounded p-2`}>
      <ElapsedTime
        state={order.state ?? 0}
        createdAt={order?.createdAt ?? ''}
        deliveryDuration={order?.deliveryDuration ?? 0}
        isPlanned={isPlanned}
        activeTimestamp={order?.plannedOrderInformation?.activeTimestamp ?? ''}
        deliveredAt={order?.deliveredAt ?? ''}
      />
      <span
        data-testid="order-delivery-status"
        className={`text-sm ${isActiveState ? 'text-blue-dark' : ''}`}
      >
        {orderStatus}
      </span>
      {order.rider && (
        <div className="flex text-white">
          <Icon icon="bike" size="small" className="mr-1" />
          <span className="truncate text-sm">{`${order.rider.firstName} ${
            order.rider.lastName?.trim()[0]
          }.`}</span>
        </div>
      )}
    </div>
  );
};

export default OrderBadge;
